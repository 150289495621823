

export function constructionOverallProgressForProject(listOfConstructionActivities, constructionProgressActivityPercentageForProject) {
  let temp
  if (listOfConstructionActivities && constructionProgressActivityPercentageForProject) {
    let weightMultipliedByProgressPerActivity = 0
    let weightofAllActivities = 0
    if (listOfConstructionActivities) {
      Object?.keys(listOfConstructionActivities)?.map((i) => {
        if (listOfConstructionActivities[i]?.parent_activity_id) {
          weightofAllActivities += listOfConstructionActivities[i]?.weight
          weightMultipliedByProgressPerActivity += (
            listOfConstructionActivities[i]?.weight * constructionProgressActivityPercentageForProject[i]
          );
        }
      });
    }

    temp = Math.floor(
      weightMultipliedByProgressPerActivity / weightofAllActivities
    );
  }
  if (isNaN(temp)) {
    temp = 0

  }
  return temp
}


export function constructionActivityProgressForBlock(listOfConstructionActivitiesProgressForSelectedBlock, activityId) {
  let temp = 0;
  if (listOfConstructionActivitiesProgressForSelectedBlock?.length > 0) {
    for (let j of listOfConstructionActivitiesProgressForSelectedBlock) {
      if (parseInt(j?.activity_id) === parseInt(activityId)) {
        temp = Math.floor(j?.progress);
      }
    }
  }
  if (isNaN(temp)) {
    temp = 0
  }
  return temp;
};

export function constructionOverallProgressForBlock(listOfConstructionActivities, listOfConstructionActivitiesProgressForSelectedBlock) {
  let weightMultipliedByProgressPerActivity = 0
  let weightofAllActivities = 0
  if (listOfConstructionActivities) {
    Object?.keys(listOfConstructionActivities)?.map((i) => {
      if (listOfConstructionActivities[i]?.parent_activity_id) {
        weightofAllActivities += listOfConstructionActivities[i]?.weight
        weightMultipliedByProgressPerActivity += (
          listOfConstructionActivities[i]?.weight * constructionActivityProgressForBlock(listOfConstructionActivitiesProgressForSelectedBlock, parseInt(i))
        );
      }
    });
  }
  let temp = Math.floor(
    weightMultipliedByProgressPerActivity / weightofAllActivities
  );
  if (isNaN(temp)) {
    temp = 0
  }
  return temp
};
export function constructionOverallProgressOfAllBlocksForProject(listOfConstructionActivities, listOfConstructionActivitiesProgressForAllBlocks) {
  let temp = {}
  for (let i in listOfConstructionActivitiesProgressForAllBlocks) {
    let weightMultipliedByProgressPerActivity = 0
    let weightofAllActivities = 0
    for (let j of listOfConstructionActivitiesProgressForAllBlocks[i]) {
      if (listOfConstructionActivities[j?.activity_id]?.parent_activity_id) {
        weightofAllActivities += listOfConstructionActivities[j?.activity_id]?.weight
        weightMultipliedByProgressPerActivity += (listOfConstructionActivities[j?.activity_id]?.weight * j?.progress)
      }
    }
    let percentage = Math.floor(weightMultipliedByProgressPerActivity / weightofAllActivities)
    if (isNaN(percentage)) {
      percentage = 0
    }
    temp[i] = percentage
  }
  return temp

};


export async function get_distributed_and_installed_bom_for_activity(activityId, selectedTrackerrow, distributedInstalledBom, totalDistributedInstalledQuantity, existingdistributedInstalledQuantity, isZimmerman, zimmermanTableWisePierPartBom) {
  let bomDetailsForSelectedTrackerrowDistributedAndInstalledWithType = {}
  let bomDetailsForSelectedTrackerrowDistributedData = []
  let bomDetailsForSelectedTrackerrowInstalledData = []
  let found_type = ""
  let distributedInstalledBomForActivity = await get_bom_details_for_activity(activityId, distributedInstalledBom)
  let totalQuantityForBomPerTrackerType = await get_total_quantity_for_bom_per_trackertype(selectedTrackerrow, distributedInstalledBomForActivity, totalDistributedInstalledQuantity, isZimmerman, zimmermanTableWisePierPartBom)
  let existingDistributedInstalledQuantityForSelectedTrackerRow = await get_existing_distributed_installed_quantity_for_trackerrow(selectedTrackerrow, existingdistributedInstalledQuantity, totalQuantityForBomPerTrackerType)
  if (distributedInstalledBomForActivity["distributed"] && distributedInstalledBomForActivity["distributed"] !== undefined) {
    found_type = "distributed"
    for (let i of distributedInstalledBomForActivity?.distributed) {
      let bomDetailsForSelectedTrackerrowDistributed = {}
      let tracker_row_id = selectedTrackerrow?.split("-")[1]
      let tracker_type_id = selectedTrackerrow?.split("-")[2]
      let bomId_trackerrowId_key = `${i?.bom_id}_${selectedTrackerrow?.split("-")[1]}`
      let bomId_trackertypeId_key = `${i?.bom_id}_${selectedTrackerrow?.split("-")[2]}`
      if (parseInt(totalQuantityForBomPerTrackerType[bomId_trackertypeId_key]) !== 0) {
        bomDetailsForSelectedTrackerrowDistributed["bom_id"] = i?.bom_id
        bomDetailsForSelectedTrackerrowDistributed["part_name"] = i?.part_name
        bomDetailsForSelectedTrackerrowDistributed["description"] = i?.description
        bomDetailsForSelectedTrackerrowDistributed["total_quantity"] = totalQuantityForBomPerTrackerType[bomId_trackertypeId_key]
        bomDetailsForSelectedTrackerrowDistributed["existing_distributed_quantity"] = existingDistributedInstalledQuantityForSelectedTrackerRow[bomId_trackerrowId_key]?.distributed
        bomDetailsForSelectedTrackerrowDistributed["tracker_type_id"] = tracker_type_id
        bomDetailsForSelectedTrackerrowDistributed["tracker_row_id"] = tracker_row_id
        bomDetailsForSelectedTrackerrowDistributed["found_type"] = found_type

      }

      if (Object.keys(bomDetailsForSelectedTrackerrowDistributed).length !== 0) {
        bomDetailsForSelectedTrackerrowDistributedData.push(bomDetailsForSelectedTrackerrowDistributed)
      }
    }
  }
  if (bomDetailsForSelectedTrackerrowDistributedData.length > 0) {
    bomDetailsForSelectedTrackerrowDistributedAndInstalledWithType[found_type] = bomDetailsForSelectedTrackerrowDistributedData
  }
  if (distributedInstalledBomForActivity["installed"] && distributedInstalledBomForActivity["installed"] !== undefined) {
    found_type = "installed"
    for (let i of distributedInstalledBomForActivity?.installed) {
      let bomDetailsForSelectedTrackerrowInstalled = {}
      let tracker_row_id = selectedTrackerrow?.split("-")[1]
      let tracker_type_id = selectedTrackerrow?.split("-")[2]
      let bomId_trackerrowId_key = `${i?.bom_id}_${selectedTrackerrow?.split("-")[1]}`
      let bomId_trackertypeId_key = `${i?.bom_id}_${selectedTrackerrow?.split("-")[2]}`
      if (parseInt(totalQuantityForBomPerTrackerType[bomId_trackertypeId_key]) !== 0) {
        bomDetailsForSelectedTrackerrowInstalled["bom_id"] = i?.bom_id
        bomDetailsForSelectedTrackerrowInstalled["part_name"] = i?.part_name
        bomDetailsForSelectedTrackerrowInstalled["description"] = i?.description
        bomDetailsForSelectedTrackerrowInstalled["total_quantity"] = totalQuantityForBomPerTrackerType[bomId_trackertypeId_key]
        bomDetailsForSelectedTrackerrowInstalled["existing_installed_quantity"] = existingDistributedInstalledQuantityForSelectedTrackerRow[bomId_trackerrowId_key]?.installed
        bomDetailsForSelectedTrackerrowInstalled["tracker_type_id"] = tracker_type_id
        bomDetailsForSelectedTrackerrowInstalled["tracker_row_id"] = tracker_row_id
        bomDetailsForSelectedTrackerrowInstalled["found_type"] = found_type

      }
      if (Object.keys(bomDetailsForSelectedTrackerrowInstalled).length !== 0) {
        bomDetailsForSelectedTrackerrowInstalledData.push(bomDetailsForSelectedTrackerrowInstalled)
      }
    }

  }

  if (bomDetailsForSelectedTrackerrowInstalledData.length > 0) {
    bomDetailsForSelectedTrackerrowDistributedAndInstalledWithType[found_type] = bomDetailsForSelectedTrackerrowInstalledData
  }

  return bomDetailsForSelectedTrackerrowDistributedAndInstalledWithType
}

export async function get_bom_details_for_activity(activityId, distributedInstalledBom) {
  let bomForSelectedActivity = {}

  for (let i in distributedInstalledBom?.distributed) {
    if (parseInt(activityId) === parseInt(i)) {
      bomForSelectedActivity["distributed"] = distributedInstalledBom?.distributed[i]
    }
  }
  for (let i in distributedInstalledBom?.installed) {
    if (parseInt(activityId) === parseInt(i)) {
      bomForSelectedActivity["installed"] = distributedInstalledBom?.installed[i]
    }
  }
  return bomForSelectedActivity
}
export async function get_total_quantity_for_bom_per_trackertype(selectedTrackerrow, distributedInstalledBomForActivity, totalDistributedInstalledQuantity, isZimmerman, zimmermanTableWisePierPartBom) {
  let totalQuantityForBomPerTrackerType = {}
  let bomId_trackerTypeIdKeyConstruction = []
  if (distributedInstalledBomForActivity["distributed"]) {
    for (let i of distributedInstalledBomForActivity?.distributed) {
      let bomId_trackertypeId_key = `${i?.bom_id}_${selectedTrackerrow?.split("-")[2]}`
      let findElm = bomId_trackerTypeIdKeyConstruction.find(
        (key) => key === bomId_trackertypeId_key
      );
      if (!findElm) {
        bomId_trackerTypeIdKeyConstruction.push(bomId_trackertypeId_key);
      }
    }
  }

  if (distributedInstalledBomForActivity["installed"]) {
    for (let i of distributedInstalledBomForActivity?.installed) {
      let bomId_trackertypeId_key = `${i?.bom_id}_${selectedTrackerrow?.split("-")[2]}`
      let findElm = bomId_trackerTypeIdKeyConstruction.find(
        (key) => key === bomId_trackertypeId_key
      );
      if (!findElm) {
        bomId_trackerTypeIdKeyConstruction.push(bomId_trackertypeId_key);
      }
    }
  }
  for (let j of bomId_trackerTypeIdKeyConstruction) {
    if (isZimmerman === true) {
      if (totalDistributedInstalledQuantity[j] !== null && totalDistributedInstalledQuantity[j] !== 0 && totalDistributedInstalledQuantity[j] !== undefined) {
        totalQuantityForBomPerTrackerType[j] = totalDistributedInstalledQuantity[j]
      } else {
        totalQuantityForBomPerTrackerType[j] = await get_total_quantity_for_bomId_tableId_for_table_wise_zimmerman(selectedTrackerrow, zimmermanTableWisePierPartBom, j)
      }
    } else {
      totalQuantityForBomPerTrackerType[j] = totalDistributedInstalledQuantity[j]
    }
  }
  return totalQuantityForBomPerTrackerType
}
export async function get_existing_distributed_installed_quantity_for_trackerrow(selectedTrackerrow, existingdistributedInstalledQuantity, totalQuantityForBomPerTrackerType) {
  let existingBomPerTrackerrowQuantity = {}
  for (let j in totalQuantityForBomPerTrackerType) {
    let bomId_trackerrowId_key = `${j.split("_")[0]}_${selectedTrackerrow?.split("-")[1]}`;
    if (existingdistributedInstalledQuantity[bomId_trackerrowId_key]) {
      existingBomPerTrackerrowQuantity[bomId_trackerrowId_key] = existingdistributedInstalledQuantity[bomId_trackerrowId_key]
    }
  }
  return existingBomPerTrackerrowQuantity
}

async function getParentActivityWeightedCompletionPercentage(activityWithWeightandPercentageData) {

  let sumOfWeights = 0
  let weightMultipliedByProgressPerActivity = 0
  Object.values(activityWithWeightandPercentageData).map((data) => {
    weightMultipliedByProgressPerActivity += data?.percentage * data?.weight
    sumOfWeights += data?.weight

  })
  let totalPercentage = weightMultipliedByProgressPerActivity / sumOfWeights
  return totalPercentage
}
async function getListOfSubActivitiesWithWeight(constructionActivities, parentActivityId, selectedTrackerrow, constructionProgressTrackerrowWise, selectedActivityId, status, deployedPartCount, totalPartCount) {
  let filteredData = Object.values(constructionActivities).filter((data) => data?.parent_activity_id === parentActivityId)
  let obj = {}
  filteredData?.map(data => {

    if (constructionProgressTrackerrowWise && constructionProgressTrackerrowWise[`${parseInt(selectedTrackerrow?.split("-")[1])}_${data?.id}`] !== undefined && constructionProgressTrackerrowWise[`${parseInt(selectedTrackerrow?.split("-")[1])}_${data?.id}`]?.completion_percentage !== 0) {
      let cpkey = constructionProgressTrackerrowWise[`${parseInt(selectedTrackerrow?.split("-")[1])}_${data?.id}`]
      if (cpkey["activity_id"] === parseInt(selectedActivityId)) {
        if (parseInt(status) === 0) {
          obj[data?.id] = { weight: data?.weight, percentage: 0 }
        } else if (parseInt(status) === 2) {
          obj[data?.id] = { weight: data?.weight, percentage: 100 }
        } else {
          if (deployedPartCount !== undefined) {
            obj[data?.id] = { weight: data?.weight, percentage: Math.round((deployedPartCount / totalPartCount) * 100) }
          }
        }
      } else {
        let completionPercentage = cpkey["completion_percentage"]
        obj[data?.id] = { weight: data?.weight, percentage: completionPercentage }
      }
    } else {
      if (parseInt(data?.id) === parseInt(selectedActivityId)) {
        if (parseInt(status) === 2) {
          obj[selectedActivityId] = { weight: data?.weight, percentage: 100 }
        } else if (parseInt(status) === 0) {
          obj[selectedActivityId] = { weight: data?.weight, percentage: 0 }
        } else {
          obj[selectedActivityId] = { weight: data?.weight, percentage: 0 }
        }
      } else if (deployedPartCount !== undefined) {
        obj[data?.id] = { weight: data?.weight, percentage: Math.round((deployedPartCount / totalPartCount) * 100) }
      } else {
        obj[data?.id] = { weight: data?.weight, percentage: 0 }
      }
    }
  })
  let parentActivityCompletionPercentage = await getParentActivityWeightedCompletionPercentage(obj)
  return parentActivityCompletionPercentage
}

const getLaydownIdForSelectedTrackerrow = (selectedTrackerrow, bulkPiersTorqueTubesBlockData) => {
  let tracker_row_id = selectedTrackerrow?.split("-")[1]
  let laydownId = null
  if (bulkPiersTorqueTubesBlockData && bulkPiersTorqueTubesBlockData?.piers) {
    if (bulkPiersTorqueTubesBlockData?.piers[tracker_row_id]) {
      laydownId = bulkPiersTorqueTubesBlockData?.piers[tracker_row_id][0]["laydown_id"]
    }
  }
  return laydownId

}

// export async function updated_progress_quantity_for_all_selected_trackerrows(updatedProgressQtyPerTracker, selectedRows) {
//   console.log(selectedRows)
//   const newData = {};
//   // Create a map to store the latest bomId updates
//   const bomUpdates = {};

//   // Loop through each selected row
//   selectedRows?.forEach(row => {
//     // Extract the tracker_row_id from the row string (2nd index)
//     const trackerRowId = row.split('-')[1];

//     // Loop through updatedProgressQtyPerTracker to get bom_id and assign new keys
//     for (const key in updatedProgressQtyPerTracker) {
//       // Extract bom_id and tracker_row_id from the key (format is bomId_trackerRowId)
//       const [bomId, existingTrackerRowId] = key.split('_');

//       // If the bomId has not been updated yet, store it in the map
//       if (!bomUpdates[bomId]) {
//         bomUpdates[bomId] = updatedProgressQtyPerTracker[key];
//       }

//       // Now update all trackerRowIds with the same bomId with the latest value
//       selectedRows.forEach(selectedRow => {
//         const selectedTrackerRowId = selectedRow.split('-')[1];
//         const newKey = `${bomId}_${selectedTrackerRowId}`;

//         // Apply the bomId's data (distributed/installed) to all trackerRowIds
//         newData[newKey] = { ...bomUpdates[bomId] };
//       });
//     }
//   });

//   return newData;
// }

export async function updated_progress_quantity_for_all_selected_trackerrows(updatedProgressQtyPerTracker, selectedRows) {
  const newData = {};

  // Loop through each selected row
  selectedRows?.forEach(row => {
    // Extract the tracker_row_id from the row string (2nd index)
    const trackerRowId = row.split('-')[1];

    // Loop through updatedProgressQtyPerTracker to get bom_id and assign new keys
    for (const key in updatedProgressQtyPerTracker) {
      // Extract bom_id and tracker_row_id from the key (format is bomId_trackerRowId)
      const [bomId, existingTrackerRowId] = key.split('_');

      // Create a new key for each selected row
      const newKey = `${bomId}_${trackerRowId}`;

      // Copy the data for this bomId to the new key
      newData[newKey] = { ...updatedProgressQtyPerTracker[key] };
    }
  });

  return newData;
}

export async function get_updated_bom_for_distributed_installed_for_selected_activity_trackerow(bulkPiersTorqueTubesBlockData, block_details, bomDetails, activityId, updateProgressQty, status, foundType, partPerTrackerType, constructionActivities, selectedTrackerrow, deployedPartCount, completedPiersTorqueTubes, isZimmerman, activityType, parentActivityId, constructionProgressTrackerrowWise) {
  let bom = []
  if (bomDetails) {
    if (Object.keys(bomDetails).length === 1) {
      for (let i in bomDetails) {
        bom = bomDetails[i]
      }
    } else {
      if (bomDetails["distributed"]) {
        for (let i of bomDetails["distributed"]) {
          bom.push(i)
        }
      }
      if (bomDetails["installed"]) {
        for (let i of bomDetails["installed"]) {
          bom.push(i)
        }
      }
    }
  }
  let arr = {};
  let bom_per_tracker = [];
  let tracker_row_id = selectedTrackerrow?.split("-")[1]
  let tracker_type_id = selectedTrackerrow?.split("-")[2]

  let partId_trackertypeId_key = `${constructionActivities[activityId]?.part_id}_${tracker_type_id}`
  let partId = constructionActivities[activityId]?.part_id;

  arr.block_id = block_details?.block_id;
  arr.block_name = block_details?.block;
  arr.tracker_row_id = parseInt(tracker_row_id);
  arr.tracker_type_id = parseInt(tracker_type_id);
  if (isZimmerman === false) {
    arr.laydown_id = getLaydownIdForSelectedTrackerrow(selectedTrackerrow, bulkPiersTorqueTubesBlockData)
  }
  arr.total_part_count = partPerTrackerType[partId_trackertypeId_key];
  arr.part_id = partId

  if (activityType === undefined && parentActivityId === undefined) {
    arr.activity_id = parseInt(activityId);
    arr.status = parseInt(status);
    if (parseInt(status) === 0) {
      arr.completion_percentage = 0;
    }
    if (parseInt(status) === 2) {
      arr.completion_percentage = 100;
    }

    if (parseInt(status) === 1) {
      let count = deployedPartCount[tracker_type_id]
      arr.completion_percentage = Math.round((parseInt(count) / partPerTrackerType[partId_trackertypeId_key]) * 100)
    }
    if (parseInt(status) === 0) {
      arr.deployed_part_count = 0;
    }
    if (parseInt(status) === 1) {
      let count = deployedPartCount[tracker_type_id]
      arr.deployed_part_count = parseInt(count)
    }
    if (parseInt(status) === 2) {
      arr.deployed_part_count = Math.round(partPerTrackerType[partId_trackertypeId_key]);
    }
    if (isZimmerman === false && parseInt(status) === 1) {
      arr.piers = completedPiersTorqueTubes[tracker_row_id] ? completedPiersTorqueTubes[tracker_row_id]["piers"] : []
      arr.torque_tubes = completedPiersTorqueTubes[tracker_row_id] ? completedPiersTorqueTubes[tracker_row_id]["torque_tubes"] : []
      // arr.piers = completedPiersTorqueTubes[tracker_type_id]["piers"]
      // arr.torque_tubes = completedPiersTorqueTubes[tracker_type_id]["torque_tubes"]
    }
    if (isZimmerman === true && parseInt(status) === 1) {
      arr.piers = completedPiersTorqueTubes
      arr.torque_tubes = []
    }
    for (let i of bom) {

      let bomId_trackerrowId_key = `${i.bom_id}_${i?.tracker_row_id}`

      if (i?.found_type === "distributed") {
        if (parseInt(status) === 2) {
          bom_per_tracker.push({
            bom_id: i.bom_id,
            distributed_quantity: i?.total_quantity,
          });
        } else if (parseInt(status) === 0) {
          bom_per_tracker.push({
            bom_id: i.bom_id,
            distributed_quantity: 0,
          });
        } else if (parseInt(status) === 1) {
          if (updateProgressQty[bomId_trackerrowId_key]) {
            let uqty =
              updateProgressQty[bomId_trackerrowId_key][
              "distributed"
              ];
            if (uqty) {
              bom_per_tracker.push({
                bom_id: i.bom_id,
                distributed_quantity: parseInt(uqty),
              });
            }
          }
        }
      } else if (i?.found_type === "installed") {
        if (parseInt(status) === 2) {
          bom_per_tracker.push({
            bom_id: i?.bom_id,
            installed_quantity: i?.total_quantity,
          });
        }
        if (parseInt(status) === 0) {
          bom_per_tracker.push({
            bom_id: i?.bom_id,
            installed_quantity: 0,
          });
        }
        if (parseInt(status) === 1) {
          if (updateProgressQty[bomId_trackerrowId_key]) {
            let uqty =
              updateProgressQty[bomId_trackerrowId_key]["installed"];
            if (uqty) {
              bom_per_tracker.push({
                bom_id: i.bom_id,
                installed_quantity: parseInt(uqty),
              });
            }
          }
        }
      }
    }
    arr.bom_per_tracker_type = bom_per_tracker;
  } else {
    let getListOfSubActivitiesWithWeightData = await getListOfSubActivitiesWithWeight(constructionActivities, parentActivityId, selectedTrackerrow, constructionProgressTrackerrowWise, activityId, status, deployedPartCount[tracker_type_id], partPerTrackerType[partId_trackertypeId_key])
    arr.activity_id = parseInt(parentActivityId);
    if (parseInt(getListOfSubActivitiesWithWeightData) === 0) {
      arr.status = 0;
      arr.completion_percentage = 0;
      arr.deployed_part_count = 0;
    }
    if (parseInt(getListOfSubActivitiesWithWeightData) === 100) {
      arr.status = 2;
      arr.completion_percentage = 100;
      arr.deployed_part_count = Math.round(partPerTrackerType[partId_trackertypeId_key]);
    }
    if (parseInt(getListOfSubActivitiesWithWeightData) > 0 && parseInt(getListOfSubActivitiesWithWeightData) < 100) {
      arr.status = 1;
      arr.completion_percentage = parseInt(getListOfSubActivitiesWithWeightData)
      arr.deployed_part_count = Math.round((parseInt(getListOfSubActivitiesWithWeightData) / 100) * partPerTrackerType[partId_trackertypeId_key])
      arr.piers = []
      arr.torque_tubes = []
    }
    arr.bom_per_tracker_type = bom_per_tracker;
  }




  return arr;
}
async function get_total_quantity_for_bomId_tableId_for_table_wise_zimmerman(selectedRows, zimmermanTableWisePierPartBom, bomId_trackertypeId_key) {
  let total_quantity = 0
  let tableId = selectedRows.split("-")[1]
  let tableTypeId = selectedRows.split("-")[2]
  let bomId = bomId_trackertypeId_key.split("_")[0]
  let tableId_bomId_key = `${tableId}_${bomId}`
  // if (zimmermanTableWisePierPartBom[tableId]) {
  //   if(zimmermanTableWisePierPartBom[tableId][bomId] && zimmermanTableWisePierPartBom[tableId][bomId]?.total_count){
  //     total_quantity = zimmermanTableWisePierPartBom[tableId][bomId]?.total_count
  //   }
  // }

  if (zimmermanTableWisePierPartBom[tableId_bomId_key] !== undefined) {
    {
      total_quantity = zimmermanTableWisePierPartBom[tableId_bomId_key]?.total_count
    }

  }
  return total_quantity

}


// export async function get_bom_for_table_wise_zimmerman(selectedActivity, selectedRows, zimmermanTableWisePierPartBom, total_installed_distributed, existing_bom_per_tracker_row) {
//   let bomData = []
//   let tableId = selectedRows.split("-")[1]
//   let tableTypeId = selectedRows.split("-")[2]
//   if (zimmermanTableWisePierPartBom[tableId]) {
//     let obj = zimmermanTableWisePierPartBom[tableId]
//     for (let i in obj) {
//       let bomObj = {}
//       let bomId_tableId_key = `${obj[i]?.bom_id}_${tableId}`
//       bomObj["bom_id"] = obj[i]?.bom_id
//       bomObj["description"] = obj[i]?.part_description
//       bomObj["part_name"] = obj[i]?.part_name
//       bomObj["total_quantity"] = obj[i]?.total_count
//       bomObj["found_type"] = "distributed"
//       bomObj["tracker_row_id"] = tableId
//       bomObj["tracker_type_id"] = tableTypeId
//       bomObj["existing_distributed_quantity"] = existing_bom_per_tracker_row[bomId_tableId_key] !== undefined ? existing_bom_per_tracker_row[bomId_tableId_key]?.distributed : undefined

//       bomData.push(bomObj)

//     }
//   }
//   return bomData

// }


export function get_construction_progress_for_parent_sub_activity(listofactivities, cpprogressactivitywise, activityId) {
  let checkActivityType = ""
  let progressPercentage = 0
  if (activityId !== undefined && listofactivities) {
    if (
      listofactivities[activityId]?.parent_activity_id === null
    ) {
      checkActivityType = "parent_activity";
    } else {
      checkActivityType = "sub_activity";
    }
    if (checkActivityType === "parent_activity") {
      let parentActivityId = activityId
      let sumOfWeights = 0
      let weightMultipliedByProgressPerActivity = 0
      Object.values(listofactivities).map((item) => {
        if (parseInt(item?.parent_activity_id) === parseInt(parentActivityId)) {
          weightMultipliedByProgressPerActivity += cpprogressactivitywise[item?.id] * item?.weight
          sumOfWeights += item?.weight
        }
      })
      progressPercentage = Math.floor(weightMultipliedByProgressPerActivity / sumOfWeights)

    } else {
      progressPercentage = Math.floor(cpprogressactivitywise[activityId])
    }
  }
  if (isNaN(progressPercentage)) {
    progressPercentage = 0
  }
  return progressPercentage

}

// export function get_list_of_piers_torquetubes_ids_with_status_for_all_selected_trackers(bulkData, selectedTrackerRows, listOfPiersTorqueTubeIdsWithStatus) {
//   let processedData = {};
//   if (Object.keys(bulkData).length > 0 && selectedTrackerRows.length > 0 && Object.keys(listOfPiersTorqueTubeIdsWithStatus).length > 0) {
//     selectedTrackerRows?.forEach(trackerRow => {
//       let trackerRowId = trackerRow?.split('-')[1];

//       if (!bulkData?.piers[trackerRowId] || !bulkData?.tt[trackerRowId]) {
//         return;
//       }

//       // Create new arrays before sorting
//       let piers = [...bulkData?.piers[trackerRowId]].sort((a, b) => (a?.pier_part_order || 0) - (b?.pier_part_order || 0));
//       let torqueTubes = [...bulkData?.tt[trackerRowId]].sort((a, b) => (a?.tt_part_order || 0) - (b?.tt_part_order || 0));

//       let processedPiers = piers?.map((pier, index) => ({
//         pier_id: pier?.id,
//         status: index < listOfPiersTorqueTubeIdsWithStatus?.piers?.length && listOfPiersTorqueTubeIdsWithStatus?.piers[index]?.status === 1 ? 1 : 0
//       }));

//       let processedTorqueTubes = torqueTubes?.map((tt, index) => ({
//         torque_tube_id: tt?.id,
//         status: index < listOfPiersTorqueTubeIdsWithStatus?.torque_tubes?.length && listOfPiersTorqueTubeIdsWithStatus?.torque_tubes[index]?.status === 1 ? 1 : 0
//       }));

//       processedData[trackerRowId] = {
//         piers: processedPiers,
//         torque_tubes: processedTorqueTubes
//       };
//     });
//     return processedData;
//   }
// }

export function get_list_of_piers_torquetubes_ids_with_status_for_all_selected_trackers(bulkData, selectedTrackerRows, listOfPiersTorqueTubeIdsWithStatus) {
  let processedData = {};

  if (Object.keys(bulkData).length > 0 && selectedTrackerRows.length > 0 && Object.keys(listOfPiersTorqueTubeIdsWithStatus).length > 0) {
    selectedTrackerRows?.forEach(trackerRow => {
      let trackerRowId = trackerRow?.split('-')[1]; // Get the trackerRowId (1st index)
      let trackerTypeId = trackerRow?.split('-')[2]; // Get the trackerTypeId (2nd index)

      // Ensure data exists for the trackerRowId
      if (!bulkData?.piers[trackerRowId] || !bulkData?.tt[trackerRowId]) {
        return;
      }

      // Get the relevant status data for the trackerTypeId
      let statusData = listOfPiersTorqueTubeIdsWithStatus[trackerTypeId];
      if (!statusData) {
        return;
      }

      // Create new arrays before sorting piers and torque tubes
      let piers = [...bulkData?.piers[trackerRowId]].sort((a, b) => (a?.pier_part_order || 0) - (b?.pier_part_order || 0));
      let torqueTubes = [...bulkData?.tt[trackerRowId]].sort((a, b) => (a?.tt_part_order || 0) - (b?.tt_part_order || 0));

      // Map piers and apply status from the status data of the same trackerTypeId
      let processedPiers = piers?.map((pier, index) => ({
        pier_id: pier?.id,
        status: index < statusData?.piers?.length ? statusData?.piers[index]?.status : 0
      }));

      // Map torque tubes and apply status from the status data of the same trackerTypeId
      let processedTorqueTubes = torqueTubes?.map((tt, index) => ({
        torque_tube_id: tt?.id,
        status: index < statusData?.torque_tubes?.length ? statusData?.torque_tubes[index]?.status : 0
      }));

      // Store the processed data under trackerRowId
      processedData[trackerRowId] = {
        piers: processedPiers,
        torque_tubes: processedTorqueTubes
      };
    });
    return processedData;
  }
}



export async function getTrackerTypeDetails(selectedRows, trackerTypes) {
  const trackerTypeMap = new Map();

  // Loop through each selected row
  selectedRows.forEach(row => {
    // Extract tracker type id (2nd index of the string when split by '-')
    const trackerTypeId = row?.split('-')[2];

    // Find the corresponding tracker type object using trackerTypeId
    const trackerType = trackerTypes.find(type => type.id == trackerTypeId);

    if (trackerType) {
      // If the tracker type exists in the map, increment the count
      if (trackerTypeMap.has(trackerTypeId)) {
        trackerTypeMap.get(trackerTypeId).count += 1;
      } else {
        // Otherwise, add the tracker type to the map with count 1
        trackerTypeMap.set(trackerTypeId, {
          tracker_type: trackerType.tracker_type,
          id: parseInt(trackerTypeId),
          count: 1
        });
      }
    }
  });

  // Convert the Map to an array of JSON objects
  const resultArray = Array.from(trackerTypeMap.values());
  return resultArray;
}






