import { IS_DEBUG_MODE_ON, IS_PROJECT_TYPE_ZIMMERMAN_FLAG } from "../featureFlags/featureFlags";
import suryadb from "../indexedDB/module";
import axios from "axios";
const _ = require("lodash");

const projectId = JSON.parse(localStorage.getItem("project_id"))

export async function insert_construction_progress_activitywise_for_blocks(cpblock) {
    for (let i in cpblock) {
        await suryadb().get_construction_progress_activitywise_for_blocks.put({ id: parseInt(i), data: cpblock[i] });
    }
}
export async function insert_construction_progress_activitywise_for_project(cpdata) {
    await suryadb().get_construction_progress_activitywise_for_project.put({ id: `projectId_${projectId}`, data: cpdata });
}
export async function insert_deployed_part_for_overall_project_block(overall_deployed_part_project, overall_deployed_part_block, blockids) {
    await suryadb().get_deployed_part_for_overall_project_block.put({
        id: `projectId_${projectId}`,
        data: overall_deployed_part_project
    });

    for (let blockid of blockids) {
        await suryadb().get_deployed_part_for_overall_project_block.put({
            id: blockid?.id,
            data: overall_deployed_part_block[blockid?.id],
        });
    }
}

export async function insert_activity_milestone_for_block(activity_milestone_for_block) {
    for (let i in activity_milestone_for_block)
        await suryadb().get_activity_milestone_for_blocks.put({ id: parseInt(i), data: activity_milestone_for_block[i] });
}

export async function insert_activity_milestone_for_project(activity_milestone_for_project) {
    await suryadb().get_activity_milestone_for_project.put({ id: `projectId_${projectId}`, data: activity_milestone_for_project });
}
export async function get_activity_milestone_for_selected_blocks(data) {
    let isZimmerman = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
    let res = await axios.post(`/projects/bom-status/activitymilestone/`, {
        block_ids: data,
    });
    let filteredData = res?.data?.filter((data) => data?.project_quantity_calc !== 0)
    let sortedBomForSelectedBlocks = []
    if (filteredData?.length > 0) {
        if (isZimmerman === true) {
            sortedBomForSelectedBlocks = filteredData
        } else {
            sortedBomForSelectedBlocks = _.sortBy(filteredData,
                [function (o) { return o.part_name }])
        }
        data?.map((i, index) => {
            if (index >= sortedBomForSelectedBlocks.length) {
                sortedBomForSelectedBlocks.push({ block_id: i })
            }
            else {
                sortedBomForSelectedBlocks[index].block_id = i
            }
        })
    }
    return sortedBomForSelectedBlocks
}
export async function get_activity_milestone_for_selected_tracker_rows(data) {
    let isZimmerman = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
    let res = await axios.post(`/projects/bom-status/activitymilestone/`, {
        tracker_row_ids: data.selected_rows,
    });
    let filteredData = res?.data?.filter((data) => data?.project_quantity_calc !== 0)
    let sortedBOMForSelectedTrackerRows = []

    if (filteredData?.length > 0) {
        if (isZimmerman === true) {
            sortedBOMForSelectedTrackerRows = filteredData
        } else {
            sortedBOMForSelectedTrackerRows = _.sortBy(filteredData,
                [function (o) { return o.part_name }])
        }
        data.selected_rows.map((i, index) => {
            if (index >= sortedBOMForSelectedTrackerRows.length) {
                sortedBOMForSelectedTrackerRows.push({ row_id: i })
            }
            else {
                sortedBOMForSelectedTrackerRows[index].row_id = i
            }
        })
        data.selected_row_no.map((i, index) => {
            sortedBOMForSelectedTrackerRows[index].row_no = i
        })
        data.tSelectedSec.map((i, index) => {
            sortedBOMForSelectedTrackerRows[index].section = i
        })
    }
    return sortedBOMForSelectedTrackerRows
}

export async function insert_parts_name() {
    let getParts = await axios.get("/part/get_parts");
    let parts = getParts.data;
    for (let i of parts) {
        await suryadb().get_parts_name.put({ id: i?.id, data: i });
    }
}
export async function insert_distributed_and_installed_bom_for_project() {
    let get_distributed_and_installed_bom_for_project = await axios.get(
        `/projects/activitymilestone/get_distributed_and_installed_bom_for_project`
    )
    let distributedInstalledBomData = get_distributed_and_installed_bom_for_project.data

    await suryadb().get_distributed_and_installed_bom_for_project.put({ id: 1, data: distributedInstalledBomData });
}

export async function insert_total_installed_distributed_quantity() {
    let get_total_installed_distributed = await axios.get(
        `/bom/get_bom_per_tracker_type_v2`
    );
    await suryadb().get_total_installed_distributed_quantity.put({
        id: 1,
        data: get_total_installed_distributed.data,
    });
}

export async function insert_existing_distributed_installed_quantity_for_trackerrow() {
    let existing_distributed_installed_quantity_for_trackerrow = await axios.get(
        `/bom/get_bom_tracker_row`
    );
    let existingDistributedInstalledQuantity = existing_distributed_installed_quantity_for_trackerrow?.data
    await suryadb().get_existing_distributed_installed_quantity_for_trackerrow.put({
        id: 1,
        data: existingDistributedInstalledQuantity,
    });
}

export async function insert_part_per_tracker_type() {
    if (!localStorage.getItem("get-data-initially")) {
        let part_per_tracker_type = await axios.get(`/tracker/part_per_tracker_type`);
        let partPerTrackerTypeData = part_per_tracker_type?.data

        await suryadb().get_part_per_tracker_type.put({ id: 1, data: partPerTrackerTypeData });
    }
}
export async function insert_table_wise_pier_part_bom() {
    let projectId = JSON.parse(localStorage.getItem("project_id"))
    let data = await axios.get(`/projects/get_table_wise_pier_part_bom_id_number_wise_count`)
    await suryadb().get_table_wise_pier_part_bom_id_number_wise_count.put({ id: `projectId_${projectId}`, data: data?.data })

}

export async function get_table_wise_pier_part_bom() {
    let data = await suryadb().get_table_wise_pier_part_bom_id_number_wise_count.toArray()
    return data[0]?.data
}

export async function insert_checklist_activity_construction() {
    if (!localStorage.getItem("get-data-initially")) {
        let trackerTypeDetails = await suryadb().get_tracker_type_names.toArray()
        trackerTypeDetails = trackerTypeDetails[0].data
        let getConstructionChecklist = [];
        let getConstructionChecklistUrls = []
        for (let i of trackerTypeDetails) {
            getConstructionChecklistUrls.push({
                url:
                    `/checklist/get_checklist?type=Construction&tracker_type_id=${i?.id}`, id: parseInt(i?.id)
            }
            );

        }
        let constructionChecklistData = await Promise.all(getConstructionChecklistUrls.map((endpoint) => axios.get(endpoint.url)))
        for (let i in constructionChecklistData) {
            let getConstructionChecklistData = constructionChecklistData[i].data
            if (constructionChecklistData[i].data !== null) {
                getConstructionChecklistData["id"] = getConstructionChecklistUrls[i]?.id;
            }
            getConstructionChecklist.push(getConstructionChecklistData);
        }
        await suryadb().get_checklist_activity_construction.put({ id: 1, data: getConstructionChecklist });
    }

}

export async function insert_construction_progress_tracker_row_wise(cp_progress_trackerrow_wise_block) {
    for (let i in cp_progress_trackerrow_wise_block) {
        await suryadb().get_construction_progress_tracker_row_wise.put({
            id: parseInt(i),
            data: cp_progress_trackerrow_wise_block[i],
        });
    }

}



export async function insert_deployed_part_for_activity(getDeployedPartForActivity) {
    for (let i in getDeployedPartForActivity) {
        await suryadb().get_deployed_part_for_activity.put({
            id: i,
            data: getDeployedPartForActivity[i],
        });
    }

}

export async function get_construction_progress_activitywise_for_blocks() {
    let data = await suryadb().get_construction_progress_activitywise_for_blocks.toArray();
    let blockActivityProgress = {}
    if (data) {
        for (let i of data) {
            blockActivityProgress[i?.id] = i?.data
        }
    }
    return blockActivityProgress
}
export async function get_construction_progress_activitywise_for_project() {
    let data = await suryadb().get_construction_progress_activitywise_for_project.toArray();
    data = data[0]?.data
    let activityProgress = {}
    if (data) {
        for (let i of data) {
            activityProgress[i?.activity_id] = i?.progress
        }
    }
    return activityProgress
}
export async function get_construction_progress_activitywise_for_svg_block() {
    let data = await suryadb().get_svg_block_progress_activitywise.toArray();
    let activityWiseBlockProgress = {}
    if (data) {
        for (let i of data) {
            activityWiseBlockProgress[i?.id] = i?.data
        }
    }
    return activityWiseBlockProgress
}
export async function get_deployed_part_for_overall_project_block() {
    let data = await suryadb().get_deployed_part_for_overall_project_block.toArray();
    let deployedPartOverall = {}
    if (data) {
        for (let i of data) {
            deployedPartOverall[i?.id] = i?.data
        }
    }
    return deployedPartOverall
}

export async function get_deployed_part_for_activitywise_project_block(type) {
    let data = await suryadb().get_deployed_part_for_activity.toArray();
    let deployedPartActivity = {}
    if (data) {
        if (type === "activitywise") {
            for (let i of data) {
                if (i?.id === "activity_wise")
                    deployedPartActivity = i?.data
            }
            return deployedPartActivity
        }
        if (type === "blockwise") {
            for (let i of data) {
                if (i?.id === "project_wise")
                    deployedPartActivity = i?.data
            }
            return deployedPartActivity
        }
    }
    return deployedPartActivity
}

export async function get_activity_milestone_block() {
    let data = await suryadb().get_activity_milestone_for_blocks.toArray();
    return data
}
export async function get_activity_milestone_project() {
    let data = await suryadb().get_activity_milestone_for_project.toArray();
    return data[0].data
}
export async function get_distributed_and_installed_bom_project() {
    let data = await suryadb().get_distributed_and_installed_bom_for_project.toArray();
    return data[0]?.data
}
export async function get_total_installed_distributed_quantities() {
    let data = await suryadb().get_total_installed_distributed_quantity.toArray();
    return data[0]?.data
}
export async function get_existing_distributed_installed_quantity_for_trackerrows() {

    let data = await suryadb().get_existing_distributed_installed_quantity_for_trackerrow.toArray();
    return data[0]?.data
}

export async function get_part_per_tracker_typewise() {
    let data = await suryadb().get_part_per_tracker_type.toArray();
    return data[0].data
}

export async function get_checklist_activity_for_construction() {
    let data = await suryadb().get_checklist_activity_construction.toArray();
    return data[0].data
}

export async function get_construction_progress_tracker_rowwise_data(data) {
    if (!isNaN(data)) {
        let cpdata = await suryadb().get_construction_progress_tracker_row_wise.toArray();
        let cpDataForSelectedBlock = {}
        if (cpdata) {
            for (let i of cpdata) {
                if (parseInt(i?.id) === parseInt(data)) {
                    cpDataForSelectedBlock = i?.data
                }
            }
        }
        return cpDataForSelectedBlock
    }
}
export async function get_parts_name() {
    let data = await suryadb().get_parts_name.toArray();
    return data

}

async function update_cp_indexedDB(data) {
    let insertCPQueue = [];
    let cpData = Object.values(data)
    for (let i of cpData[0]) {
        insertCPQueue.push({
            id: `${i?.block_id}_${i.tracker_row_id}_${i.activity_id}`,
            data: i
        })
    }
    await suryadb().update_construction_progress.bulkPut(insertCPQueue);

}
async function update_construction_progress_trackerrow_wise_indexedDB(data) {
    let getcpdata = await suryadb().get_construction_progress_tracker_row_wise.toArray();
    let cpdata = {}
    let blockId = Object.keys(data)
    let updatedValue = Object.values(data)
    for (let i of getcpdata) {
        if (parseInt(i?.id) === parseInt(blockId)) {
            cpdata = i?.data
        }
    }
    if (updatedValue.length > 0) {
        for (let i of updatedValue[0]) {
            if (cpdata[`${i.tracker_row_id}_${i.activity_id}`]) {
                cpdata[`${i.tracker_row_id}_${i.activity_id}`].completion_percentage = i.completion_percentage
                cpdata[`${i.tracker_row_id}_${i.activity_id}`].deployed_part_count = i.deployed_part_count
                cpdata[`${i.tracker_row_id}_${i.activity_id}`].status = i.status
                cpdata[`${i.tracker_row_id}_${i.activity_id}`].total_part_count = i.total_part_count
                if (parseInt(i.status) === 1) {
                    cpdata[`${i.tracker_row_id}_${i.activity_id}`].piers = i?.piers !== undefined ? i?.piers : []
                    cpdata[`${i.tracker_row_id}_${i.activity_id}`].torque_tubes = i?.torque_tubes !== undefined ? i?.torque_tubes : []
                }

            } else {
                cpdata[`${i.tracker_row_id}_${i.activity_id}`] = {
                    activity_id: i?.activity_id,
                    completion_percentage: i?.completion_percentage,
                    deployed_part_count: i?.deployed_part_count,
                    id: Math.round(Math.random() * 100),
                    status: i?.status,
                    total_part_count: i?.total_part_count,
                    piers: i?.piers ? i?.piers : [],
                    torque_tubes: i?.torque_tubes ? i?.torque_tubes : []
                }
            }
        }
    }

    for (let i of getcpdata) {
        if (parseInt(i?.id) === parseInt(blockId)) {
            await suryadb().get_construction_progress_tracker_row_wise.put({
                id: parseInt(blockId),
                data: cpdata,
            });
        }
    }
}

export async function update_existing_distributed_installed_quantities(data) {
    let get_existing_data = await suryadb().get_existing_distributed_installed_quantity_for_trackerrow.toArray();
    let existing_data = get_existing_data[0]?.data;
    for (let i in data) {
        if (!existing_data[i]) {
            existing_data[i] = {};

        }
        if (data[i]["installed"]) {
            existing_data[i]["installed"] = data[i]["installed"];
        }
        if (data[i]["distributed"]) {
            existing_data[i]["distributed"] = data[i]["distributed"];
        }
    }
    await suryadb().get_existing_distributed_installed_quantity_for_trackerrow.clear();
    await suryadb().get_existing_distributed_installed_quantity_for_trackerrow.put({
        id: 1,
        data: existing_data,
    });
}

export async function update_construction_progress_indexedDB(data) {
    await update_cp_indexedDB(data)
    await update_construction_progress_trackerrow_wise_indexedDB(data)

}

export async function update_construction_progress() {
    let pids = []
    try {
        //updating data
        let updatedBlocks = JSON.parse(localStorage.getItem("updated-blocks")) || {}
        let dcp = await suryadb().update_construction_progress.toArray();
        let blockids = new Set()


        for (let i of dcp) {
            blockids.add(`${i.data.block_id}_${i?.data.activity_id}`)
            updatedBlocks[i.data.block_id] = new Date().getTime()
        }
        blockids = Array.from(blockids)
        for (let j of blockids) {
            let dataArr = []
            for (let i of dcp) {
                let blockid_activityid = `${i.data.block_id}_${i?.data.activity_id}`
                if (j === blockid_activityid) {
                    dataArr.push(i?.data)
                }
            }
            let block_id = j.split("_")[0]
            let resCP = await axios.patch(`/progress/update_construction_progress?block_id=${block_id}`, {
                data: dataArr,
            });
            if (IS_DEBUG_MODE_ON === true) {
                await suryadb().sync_request_details.put({
                    id: resCP?.data?.pid,
                    data: {
                        updatedTime: new Date().toLocaleString(),
                        status: 0,
                        data: dataArr
                    }
                });
            } else {
                await suryadb().sync_request_details.put({
                    id: resCP?.data?.pid,
                    data: {
                        updatedTime: new Date().toLocaleString()
                    }
                });
            }
            pids.push(resCP?.data?.pid)
        }

        await suryadb().update_construction_progress.clear();
        localStorage.setItem("updated-blocks", JSON.stringify(updatedBlocks))
    } catch (err) {
        console.log(err);
    }
    return pids
}