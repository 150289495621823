import { Box } from "@mui/system";
import { Divider, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useAtom } from "jotai";
import dashboardAtom, {
  fetchActivityIdAtom,
  fetchConstructionProgressForProjectAtom,
} from "../../data/dashboardAtom";
import deployedPartAtom, {
  fetchDeployedPartActivityWiseAtom,
  fetchDeployedPartOverallProjectBlockAtom,
} from "../../data/deployedPartAtom";
import partsAtom, { fetchPartsAtom } from "../../data/partsAtom";
import ActivitySelect from "../../components/selectActivity";
import syncStatusAtom from "../../data/syncStatusAtom";
import activitiesAtom, { fetchActivitiesAtom } from "../../data/activitiesAtom";
import { constructionOverallProgressForProject, get_construction_progress_for_parent_sub_activity } from "../../components/functions/constructionProgress";
// import {ActivitySelectBox} from '@product-fusion/surya-fw';


const ConstructionProgressHeader = ({ setter }) => {
  const [dashboard] = useAtom(dashboardAtom);
  const [deployedPart] = useAtom(deployedPartAtom)
  const [listParts] = useAtom(partsAtom);
  const [activities] = useAtom(activitiesAtom);
  const [syncStatusData] = useAtom(syncStatusAtom);
  const [, fetchDeployedPartActivityWise] = useAtom(fetchDeployedPartActivityWiseAtom);
  const [, fetchDeployedPartForOverallProjectBlock] = useAtom(fetchDeployedPartOverallProjectBlockAtom);
  const [, fetchParts] = useAtom(fetchPartsAtom);
  const [, fetchActivities] = useAtom(fetchActivitiesAtom);
  const [, fetchActivityIddata] = useAtom(fetchActivityIdAtom);
  const [, fetchConstructionProgressForProject] = useAtom(fetchConstructionProgressForProjectAtom)


  useEffect(() => {
    init();
  }, [syncStatusData.syncing]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      await fetchActivities();
      await fetchParts();
      await fetchDeployedPartActivityWise("activitywise");
      await fetchDeployedPartForOverallProjectBlock();
      await fetchConstructionProgressForProject()
    } catch (err) {
      console.log(err);
    }
  };

  const projectId = JSON.parse(localStorage?.getItem("project_id"))
  // Returns selected activity in dropdown
  const handleActivityChange = async (e) => {
    let SelectedActivityId = ""
    if (e === "All" || e === "None Selected") {
      SelectedActivityId = e
    } 
    else {
      SelectedActivityId = e[0]
    }

    localStorage.setItem("construction-act-id", SelectedActivityId)
    setter(SelectedActivityId);
    try {
      await fetchActivityIddata(SelectedActivityId)
    }
    catch (err) {
      console.log(err)
    }
  };

  // Deployed count for Activity
  const deployedCount = () => {
    let temp = {};
    if (deployedPart?.getDeployedPartForActivity && localStorage.getItem("construction-act-id") !== "None Selected") {
      let activityId = JSON.parse(localStorage.getItem("construction-act-id"))
      let deployedPartCount = deployedPart?.getDeployedPartForActivity[activityId]
      if (deployedPartCount?.deployed_part_count) {
        temp["deployed_count"] = deployedPartCount?.deployed_part_count[0]?.deployed_count
        temp["total_part_count"] = deployedPartCount?.total_part_count
      }
    }
    if (Object.keys(temp).length === 0) {
      temp = null
    }
    return temp;
  };
  return (
    <Box sx={{ backgroundColor: "rgba(105, 111, 121, 0.04)", display: "flex" }}>
      <Box sx={{ display: "flex" }}>
        <Grid item>
          <Box
            sx={{
              display: "flex",
              height: "84px",
              justifyContent: "center",
              alignItems: "center",
              marginX: "15px",
            }}
          >
            <Box>
              <Typography
                sx={{ paddingLeft: "5px", fontSize: "14px", marginTop: "2px" }}
              >
                {activities?.construction && localStorage.getItem("construction-act-id") !== "All" && localStorage.getItem("construction-act-id") !== "None Selected" &&
                  activities?.construction[JSON.parse(localStorage.getItem("construction-act-id"))]?.parent_activity_id
                  ? activities?.construction[
                    activities?.construction[JSON.parse(localStorage.getItem("construction-act-id"))]
                      ?.parent_activity_id
                  ].name
                  : "Construction Progress"}
              </Typography>
              {/* <ActivitySelectBox
                className="pl-4 py-1.5 bg-inherit rounded focus:outline-none focus:border-gray-300 w-44 font-bold text-sm mt-1 z-10 "
                Overall={true}
                placeholder="Select Activity"
                activities={activities?.construction}
                onChange={handleActivityChange}
                NoneSelectedActivity={true}
                NoSelection={false}
                multiselect={false}
                defaultValue={localStorage.getItem("construction-act-id")}
              /> */}
              <ActivitySelect
                placeholder="Select Activity"
                activities={activities?.construction}
                activities_disabled={false}
                sub_activities_disabled={false}
                multiselect={false}
                onChange={handleActivityChange}
                width="230px"
                textbold={true}
                leftAlign="230px"
                border="none"
                fontSize="15px"
              />
            </Box>

            <Box sx={{ paddingLeft: "25px" }}>
              <Typography
                sx={{
                  color: "#F16253",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                {localStorage.getItem("construction-act-id") === "None Selected" ? "-" : localStorage.getItem("construction-act-id") === "All"
                  ? constructionOverallProgressForProject(activities?.construction, dashboard?.constructionProgressForProject)
                  : get_construction_progress_for_parent_sub_activity(activities?.construction, dashboard?.constructionProgressForProject, JSON.parse(localStorage.getItem("construction-act-id")))}
                {localStorage.getItem("construction-act-id") !== "None Selected" && "%"}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <Grid item>
          <Box
            sx={{
              display: "flex",
              height: "84px",
              justifyContent: "space-between",
              alignItems: "center",
              marginX: "15px",
              width: "calc(100vw - 760px)",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: "14px" }}>Counts</Typography>
              {localStorage.getItem("construction-act-id") === "All" || localStorage.getItem("construction-act-id") === "None Selected" ? (
                <Typography style={{ fontSize: "16px", fontWeight: "700", textAlign: "center" }}>
                  _
                </Typography>
              ) : (
                <Typography
                  style={{ fontSize: "14px", fontWeight: "500", width: "15vw", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {listParts?.map((j) => {
                    return (
                      <>
                        {localStorage.getItem("construction-act-id") !== "All" && activities?.construction[JSON.parse(localStorage.getItem("construction-act-id"))]
                          ? j.id ===
                            activities?.construction[JSON.parse(localStorage.getItem("construction-act-id"))]?.part_id
                            ? j?.data?.part_name
                            : null
                          : null}
                      </>
                    );
                  })}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {localStorage.getItem("construction-act-id") === "All" || localStorage.getItem("construction-act-id") === "None Selected" ? (
                <Typography
                  sx={{
                    display: "flex",
                    color: "#F16253",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                      marginTop: "7px",
                    }}
                  >
                    {/* {deployedPart?.getDeployedPartForOverall[`projectId_${projectId}`]?.deployed} */}
                    -
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "26px",
                      fontWeight: "700",
                      marginX: "5px",
                    }}
                  >
                    /
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                      marginTop: "7px",
                    }}
                  >
                    {/* {deployedPart?.getDeployedPartForOverall[`projectId_${projectId}`]?.total} */}
                    -
                  </Typography>
                </Typography>

              ) : (
                <Typography
                  sx={{
                    display: "flex",
                    color: "#F16253",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                      marginTop: "7px",
                    }}
                  >
                    {deployedCount() && deployedCount()?.deployed_count !== null ? deployedCount()?.deployed_count : "-"}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "26px",
                      fontWeight: "700",
                      marginX: "5px",
                    }}
                  >
                    /
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                      marginTop: "7px",
                    }}
                  >
                    {deployedCount() ? deployedCount()?.total_part_count : "-"}
                  </Typography>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default ConstructionProgressHeader;