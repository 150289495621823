import axios from "axios";
import produce from "immer";
import { insert_activity_milestone_for_block, insert_activity_milestone_for_project, insert_construction_progress_activitywise_for_blocks, insert_construction_progress_activitywise_for_project, insert_construction_progress_tracker_row_wise, insert_deployed_part_for_activity, insert_deployed_part_for_overall_project_block, insert_checklist_activity_construction, insert_distributed_and_installed_bom_for_project, insert_existing_distributed_installed_quantity_for_trackerrow, insert_part_per_tracker_type, insert_parts_name, insert_total_installed_distributed_quantity, update_construction_progress, get_table_wise_pier_part_bom, insert_table_wise_pier_part_bom } from "../dal/constructionProgress";
import { insert_activities } from "../dal/activities";
import { insert_blocks, insert_pier_legend_details, insert_svg_block_progress_activity_wise, insert_svg_map, insert_bulk_piers_torquetubes, insert_d3_xscale_yscale_blockwise, insert_zimmerman_table_coordinates, insert_zimmerman_bulk_piers } from "../dal/layout";
import { insert_tracker_types_for_block, insert_tracker_types_for_project, insert_tracker_types_name } from "../dal/trackerTypes";
import { insert_qc_bulk_checklist_data, insert_qc_custom_response, insert_qc_progress_qc_punchlist, update_quality_control } from "../dal/qualityCheckProgress";
import { fetchImages } from "../dal/images";
import { IS_PROJECT_TYPE_ZIMMERMAN_FLAG, IS_QC_ENABLED_FLAG } from "../featureFlags/featureFlags";

let isCancelled = false;

export const cancelGetData = () => {
  isCancelled = true;
};

export const resetCancellation = () => {
  isCancelled = false;
};

const withCancellationCheck = async (operation) => {
  if (isCancelled) {
    console.log("Operation cancelled");
    return null;
  }
  return await operation();
};


export const getData = async (updateSyncStatus) => {
  let is_qc_enabled = await IS_QC_ENABLED_FLAG()
  let is_zimmeran = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
  let startTime = Date.now()
  let bulk_data;
  let blockids;

  //fetching data

  try {
    // Fetch bulk data
    bulk_data = await withCancellationCheck(async () => {
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 1 }));
      const response = await axios.get("/tablet/get_all_data");
      return response.data;
    });
    if (isCancelled) return;

    // Construction progress
    await withCancellationCheck(async () => {
      let cpdata = bulk_data.get_project_overall_progress.construction;
      await insert_construction_progress_activitywise_for_project(cpdata);
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 2 }));
    });
    if (isCancelled) return;

    // Insert piers data
    await withCancellationCheck(async () => {
      if (is_zimmeran === true) {
        await insert_zimmerman_bulk_piers();
      } else {
        await insert_bulk_piers_torquetubes();
      }
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 3 }));
    });
    if (isCancelled) return;

    // Tracker type data
    await withCancellationCheck(async () => {
      let trackerTypeData = bulk_data.get_count_tracker_type_wise_row_for_project;
      await insert_tracker_types_for_project(trackerTypeData);
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 4 }));
    });
    if (isCancelled) return;

    // Activities
    await withCancellationCheck(async () => {
      let activities = bulk_data.fetch_all_activities_group_act_id_for_project;
      await insert_activities(activities);
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 5 }));
    });
    if (isCancelled) return;

    // Blocks
    await withCancellationCheck(async () => {
      blockids = bulk_data.tracker_row_blocks;
      await insert_blocks(blockids);
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 6 }));
    });
    if (isCancelled) return;



    await withCancellationCheck(async () => {
      let cpblock = bulk_data.get_progress_activity_wise.construction
      await insert_construction_progress_activitywise_for_blocks(cpblock)
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 7 }));
    });
    if (isCancelled) return;


    await withCancellationCheck(async () => {
      await insert_d3_xscale_yscale_blockwise(blockids)
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 8 }));
    });
    if (isCancelled) return;

    await withCancellationCheck(async () => {
      let trackerTypeBlockData = bulk_data.get_count_tracker_type_wise_row_for_block
      await insert_tracker_types_for_block(trackerTypeBlockData)
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 9 }));
    });
    if (isCancelled) return;


    //overall linked part for project and block
    await withCancellationCheck(async () => {
      let overall_deployed_part_project = bulk_data.get_deployed_part_for_project_overall
      let overall_deployed_part_block = bulk_data.get_deployed_part_for_project_for_block
      await insert_deployed_part_for_overall_project_block(overall_deployed_part_project, overall_deployed_part_block, blockids)
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 10 }));
    });
    if (isCancelled) return;

    await withCancellationCheck(async () => {
      let activityMilestoneBlock = bulk_data.get_all_activity_milestone_for_project
      await insert_activity_milestone_for_block(activityMilestoneBlock)
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 11 }));
    });
    if (isCancelled) return;

    await withCancellationCheck(async () => {
      let activityMilestoneProject = bulk_data.get_project_wide_activity_milestone
      await insert_activity_milestone_for_project(activityMilestoneProject)
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 12 }));
    });
    if (isCancelled) return;

    // parts details
    await withCancellationCheck(async () => {
      await insert_parts_name()
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 13 }));
    });
    if (isCancelled) return;

    //svg file
    await withCancellationCheck(async () => {
      await insert_svg_map()
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 14 }));
    });
    if (isCancelled) return;
    // cp distributed_and_installed_bom_for_project
    await withCancellationCheck(async () => {
      await insert_distributed_and_installed_bom_for_project()
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 15 }));
    });
    if (isCancelled) return;
    //cp total installed or distributed per bom id and activity id
    await withCancellationCheck(async () => {
      await insert_total_installed_distributed_quantity()
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 16 }));
    });
    if (isCancelled) return;

    // existing_distributed_installed_quantity_for_trackerrow
    await withCancellationCheck(async () => {
      await insert_existing_distributed_installed_quantity_for_trackerrow()
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 17 }));
    });
    if (isCancelled) return;
    // part per tracker type
    await withCancellationCheck(async () => {
      await insert_part_per_tracker_type()
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 18 }));
    });
    if (isCancelled) return;
    // pier legend details
    await withCancellationCheck(async () => {
      await insert_pier_legend_details()
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 19 }));
    });
    if (isCancelled) return;

    await withCancellationCheck(async () => {
      let cp_progress_tracker_row_wise = bulk_data?.get_construction_progress_tracker_row_wise_block.construction
      await insert_construction_progress_tracker_row_wise(cp_progress_tracker_row_wise)
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 20 }));
    });
    if (isCancelled) return;
    await withCancellationCheck(async () => {
      if (is_qc_enabled === true) {
        await insert_qc_progress_qc_punchlist(bulk_data?.get_qc_pier_and_tt_revamp_progress)
      }
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 21 }));
    });
    if (isCancelled) return;
    await withCancellationCheck(async () => {
      if (is_qc_enabled === true) {
        await fetchImages(updateSyncStatus)
      }
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 22 }));
    });
    if (isCancelled) return;

    //tracker type names
    await withCancellationCheck(async () => {
      if (is_zimmeran === true) {
        await insert_zimmerman_table_coordinates(blockids)
      }
      await insert_tracker_types_name()
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 23 }));
    });
    if (isCancelled) return;
    // checklist activity construction
    await withCancellationCheck(async () => {
      await insert_checklist_activity_construction()
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 24 }));
    });
    if (isCancelled) return;

    //deployed part for activity
    await withCancellationCheck(async () => {
      let getDeployedPartForActivity = bulk_data?.get_deployed_part_for_activity_in_block
      await insert_deployed_part_for_activity(getDeployedPartForActivity)
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 25 }));
    });
    if (isCancelled) return;
    // svg block progress activity wise
    await withCancellationCheck(async () => {
      await insert_svg_block_progress_activity_wise()
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 26 }));
    });
    if (isCancelled) return;
    await withCancellationCheck(async () => {
      if (is_qc_enabled === true) {
        await insert_qc_bulk_checklist_data()
        await insert_qc_custom_response(bulk_data?.get_custom_response_tracker_row_wise_block)
      }
      updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 27 }));
    });

    if (isCancelled) return;
    await withCancellationCheck(async () => {
      if (is_zimmeran === true) {
        await insert_table_wise_pier_part_bom()
        updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 28 }));
      }
    });

    if (isCancelled) return;

    await withCancellationCheck(async () => {
      if (!isCancelled) {
        let endTime = Date.now();
        let totalTime = endTime - startTime;
        console.log("initial get data", (totalTime / 1000), "s");
        localStorage.setItem("last-sync", Date.now());
        localStorage.setItem("get-data-initially", true);
        localStorage.setItem("updating_getting_data", false);
      }
    });
  } catch (error) {
    console.error("Error in getData:", error);
    throw error;
  }
};

let pidsWithSyncInitiateValue = {}

export const updateCpData = async () => {
  let updatecp = await update_construction_progress()
  for (let i of updatecp) {
    pidsWithSyncInitiateValue[i] = 0
  }
  return updatecp
};

export const updateQcData = async () => {
  let updateqc = await update_quality_control()
  for (let i of updateqc) {
    pidsWithSyncInitiateValue[i] = 0
  }
  return updateqc
}