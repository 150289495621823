import {
  Box,
  Divider,
  Typography,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import activitiesAtom, { fetchActivitiesAtom } from "../../data/activitiesAtom";
import dashboardAtom, {
  fetchBlockAtom,
  fetchActivityIdAtom,
  fetchConstructionActivityIdAtom,
  fetchConstructionProgressForBlockAtom,
  fetchSelectedTrackerTypeIdsAtom,
  fetchPreviousSelectedTrackerTypeIdAtom,
  fetchHighLightSelectedTrackerTypeIdsAtom
} from "../../data/dashboardAtom";
import deployedPartAtom, { fetchDeployedPartOverallProjectBlockAtom, fetchDeployedPartActivityWiseAtom } from "../../data/deployedPartAtom";
import partsAtom, { fetchPartsAtom } from "../../data/partsAtom";
import ActivitySelect from "../../components/selectActivity";
import selectedRowsAtom, { updateSelectedRowsAtom } from "../../data/selectedRowsAtom";
import syncStatusAtom from "../../data/syncStatusAtom";
import { constructionActivityProgressForBlock, constructionOverallProgressForBlock, get_construction_progress_for_parent_sub_activity } from "../../components/functions/constructionProgress";

const BlockConstructionProgressHeader = (props) => {
  const [listparts] = useAtom(partsAtom);
  const [activities] = useAtom(activitiesAtom);
  const [dashboard] = useAtom(dashboardAtom);
  const [syncStatusData] = useAtom(syncStatusAtom);
  const [selectedRows] = useAtom(selectedRowsAtom);
  const [deployedPart] = useAtom(deployedPartAtom)
  const [, fetchpartsData] = useAtom(fetchPartsAtom);
  const [, fetchDeployedPartActivityWise] = useAtom(fetchDeployedPartActivityWiseAtom);
  const [, fetchDeployedPartForOverallProjectBlock] = useAtom(fetchDeployedPartOverallProjectBlockAtom);
  const [, fetchBlockData] = useAtom(fetchBlockAtom);
  const [, fetchActivities] = useAtom(fetchActivitiesAtom);
  const [, fetchActivityIddata] = useAtom(fetchActivityIdAtom);
  const [, constructionActivityId] = useAtom(fetchConstructionActivityIdAtom);
  const [, fetchConstructionProgressForBlock] = useAtom(fetchConstructionProgressForBlockAtom)
  const [, fetchSelectedTrackerTypeIds] = useAtom(fetchSelectedTrackerTypeIdsAtom)
  const [, fetchPreviousSelectedTrackerTypeId] = useAtom(fetchPreviousSelectedTrackerTypeIdAtom)
  const [, fetchHighLightSelectedTrackerTypeIds] = useAtom(fetchHighLightSelectedTrackerTypeIdsAtom)
  const [, updateSelectedRows] = useAtom(updateSelectedRowsAtom)
  const [constructionProgressActivityWiseForBlock, setConstructionProgressActivityWiseForBlock] = useState({})
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    init();
  }, [syncStatusData.syncing]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    let cpActivitywiseProgressBlock = {}
    let constructionProgressForBlock = dashboard?.constructionProgressForBlock[params.id]
    if (constructionProgressForBlock) {
      constructionProgressForBlock?.map((item) => {
        let activityId = item?.activity_id
        cpActivitywiseProgressBlock[activityId] = item?.progress
      })
    setConstructionProgressActivityWiseForBlock(cpActivitywiseProgressBlock)

    }
  }, [dashboard?.constructionProgressForBlock])

  const handleBackButton = async () => {
    try {
      await fetchPreviousSelectedTrackerTypeId(null)
      await fetchSelectedTrackerTypeIds([])
      await fetchHighLightSelectedTrackerTypeIds([])
      await updateSelectedRows([])
    } catch (err) {
      console.log(err)
    }
    navigate("/home/dashboard");
  }

  const init = async () => {
    try {
      await fetchConstructionProgressForBlock()
      await fetchActivities();
      await fetchBlockData();
      await fetchpartsData();
      await fetchDeployedPartForOverallProjectBlock();
      await fetchDeployedPartActivityWise("blockwise");
      await constructionActivityId()
      await fetchActivityIddata()
    } catch (err) {
      console.log(err);
    }
  };

  // Returns block name
  let blockDetails = dashboard.block.find((y) => parseInt(y.id) === parseInt(params.id));

  // stores the selected activity in localstorage and atom
  const handleActivityChange = async (e) => {
    let SelectedActivityId = ""
    if (e === "All" || e === "None Selected") {
      SelectedActivityId = e
    } 
    else {
      SelectedActivityId = e[0]
    }
    localStorage.setItem("construction-act-id", SelectedActivityId)
    try {
      await fetchActivityIddata(SelectedActivityId)
    }
    catch (err) {
      console.log(err)
    }
  };


  // Deployed count for selected activity
  const deployedCount = () => {
    let temp = {};
    
    if (deployedPart?.getDeployedPartForActivity && localStorage.getItem("construction-act-id") !== "None Selected") {
      let activityId = JSON.parse(localStorage.getItem("construction-act-id"))
      let key = `${params?.id}_${activityId}`
      let deployedPartForCurrentBlockSelectedActivityId = deployedPart?.getDeployedPartForActivity[key]
      if (deployedPartForCurrentBlockSelectedActivityId?.deployed_part_count) {
        temp["deployed_count"] = deployedPartForCurrentBlockSelectedActivityId?.deployed_part_count[0]?.deployed_count
        temp["total_part_count"] = deployedPartForCurrentBlockSelectedActivityId?.total_part_count
      }
    }
    if (Object.keys(temp).length === 0) {
      temp = null
    }
    return temp
  };

  return (
    <Box
      sx={{
        backgroundColor: "rgba(105, 111, 121, 0.04)",
        display: "flex",
        height: "86px",
      }}
    >
      <Grid item>
        <Box
          sx={{
            backgroundColor: "#262223",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: "12px 5px 8px",
            height: "65px",
            width: "70px",
          }}
          onClick={handleBackButton}
        >
          <ArrowBackIcon />
          <Typography>{blockDetails?.data?.name}</Typography>
        </Box>
      </Grid>
      <Box>
        <Grid item>
          <Box
            sx={{
              display: "flex",
              height: "95px",
              justifyContent: "space-between",
              alignItems: "center",
              marginX: "12px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: "14px", paddingLeft: "6px" }}>

                {activities.length > 0 && (localStorage.getItem("construction-act-id") === "All" || localStorage.getItem("construction-act-id") !== "None Selected")  ? "Activity" : (
                  <>
                    {activities.construction && activities.construction[localStorage.getItem("construction-act-id")]?.parent_activity_id && activities.construction[
                      activities.construction[localStorage.getItem("construction-act-id")]?.parent_activity_id
                    ]
                      ? activities.construction[
                        activities.construction[localStorage.getItem("construction-act-id")]
                          ?.parent_activity_id
                      ]?.name
                      : "Activity"}
                  </>)

                }

              </Typography>
              <ActivitySelect
                placeholder="Select Activity"
                activities={activities.construction ? activities.construction : null}
                activities_disabled={false}
                sub_activities_disabled={false}
                multiselect={false}
                onChange={handleActivityChange}
                width="195px"
                textbold={true}
                act={
                  localStorage.getItem("construction-act-id") ? localStorage.getItem("construction-act-id") : null
                }
                leftAlign="275px"
                border="none"
                fontSize="15px"
              />
            </Box>
            <Box sx={{ paddingLeft: "25px" }}>
              <Typography
                sx={{ color: "#F16253", fontWeight: "700", fontSize: "20px" }}
              >
                {localStorage.getItem("construction-act-id") === "None Selected" ? "-" :localStorage.getItem("construction-act-id") === "All"
                  ? constructionOverallProgressForBlock(activities?.construction, dashboard?.constructionProgressForBlock[params?.id])
                  : get_construction_progress_for_parent_sub_activity(activities?.construction, constructionProgressActivityWiseForBlock,JSON.parse(localStorage.getItem("construction-act-id")))}
                {localStorage.getItem("construction-act-id") !== "None Selected" && "%"}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <Grid item>
          <Box
            sx={{
              display: "flex",
              height: "95px",
              justifyContent: "space-between",
              alignItems: "center",
              marginX: "12px",
              width: "30vw",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: "14px" }}>Counts</Typography>

              {localStorage.getItem("construction-act-id") === "All" || localStorage.getItem("construction-act-id") === "None Selected"  ? (
                <Typography
                  sx={{
                    fontSize: "17px",
                    fontWeight: "700",
                    textAlign: "center"
                  }}
                >
                  _
                </Typography>
              ) : (
                <Typography
                  style={{ fontSize: "14px", fontWeight: "500", width: "15vw", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {listparts?.map((j) => {
                    return (
                      <>
                        {localStorage.getItem("construction-act-id") !== "All" 
                          ? j.id ===
                            activities.construction[JSON.parse(localStorage.getItem("construction-act-id"))]?.part_id
                            ? j?.data?.part_name
                            : null
                          : null}
                      </>
                    );
                  })}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {(localStorage.getItem("construction-act-id") === "All" || localStorage.getItem("construction-act-id") === "None Selected" ) && selectedRows.length === 0 ? (

                <Typography
                  sx={{
                    display: "flex",
                    color: "#F16253",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                      marginTop: "7px",
                    }}
                  >
                    -{/* {deployedPart?.getDeployedPartForOverall[params?.id]?.deployed} */}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "26px",
                      fontWeight: "900",
                      marginX: "5px",
                    }}
                  >
                    /
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                      marginTop: "7px",
                    }}
                  >
                    {/* {deployedPart?.getDeployedPartForOverall[params?.id]?.total} */}-
                  </Typography>
                </Typography>
              ) : navigator.onLine &&
                localStorage.getItem("construction-act-id") === "All" && localStorage.getItem("construction-act-id") === "None Selected"  && 
                selectedRows.length > 0 ? (
                <Typography
                  sx={{
                    display: "flex",
                    color: "#F16253",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                      marginTop: "7px",
                    }}
                  >
                    -
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "26px",
                      fontWeight: "900",
                      marginX: "5px",
                    }}
                  >
                    /
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                      marginTop: "7px",
                    }}
                  >
                    -
                  </Typography>
                </Typography>
              )
                : localStorage.getItem("construction-act-id") !== "All" && localStorage.getItem("construction-act-id") !== "None Selected"  ? (
                  <Typography
                    sx={{
                      display: "flex",
                      color: "#F16253",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        marginTop: "7px",
                      }}
                    >
                      {deployedCount() ? deployedCount()?.deployed_count === 0 ? "-" : deployedCount()?.deployed_count : "-"}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "26px",
                        fontWeight: "900",
                        marginX: "5px",
                      }}
                    >
                      /
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        marginTop: "7px",
                      }}
                    >
                      {deployedCount() ? deployedCount()?.total_part_count === 0 ? "-" : deployedCount()?.total_part_count : "-"}
                    </Typography>
                  </Typography>
                ) : null}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default BlockConstructionProgressHeader;