import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { BsChevronDown } from "react-icons/bs";
import { Typography, TextField, Box, List, ListItem } from "@mui/material";

const ActivitySelect = ({
  placeholder,
  activities,
  activities_disabled,
  sub_activities_disabled,
  multiselect,
  onChange,
  width,
  disabled,
  textbold,
  empty,
  act,
  leftAlign,
  border,
  fontSize
}) => {
  const rearrangeData = (data) => {
    const result = [];

    // Step 1: Find activities with null parent_activity_id
    const activitiesWithNullParent = Object.values(data).filter(
      (activity) => activity.parent_activity_id === null
    );

    // Step 2: Loop through activities and their corresponding subactivities
    activitiesWithNullParent.forEach((activity) => {
      const { id } = activity;
      result.push(activity);

      const subactivities = Object.values(data).filter(
        (activity) => activity.parent_activity_id === id
      );

      subactivities.forEach((subactivity) => {
        // const { id } = subactivity;
        result.push(subactivity);
      });
    });
    return result;
  };

  const [selectedItems, setSelectedItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleItemChange = (itemId) => {
    if (itemId !== "All" && itemId !== "None Selected") {
      let updatedItems;

      if (multiselect) {
        if (selectedItems.includes(itemId)) {
          updatedItems = selectedItems.filter((item) => item !== itemId);
        } else {
          updatedItems = [...selectedItems, itemId];
        }
      } else {
        updatedItems = [itemId];
      }
      setSelectedItems(updatedItems);
      onChange(updatedItems);
    } else {
      setSelectedItems([]);
    }
  };

  const handleFocus = () => {
    setIsOpen(!isOpen);
  };

  const handleActivityClick = (itemId) => {
    if (itemId !== "All" && itemId !== "None Selected") {
      if (multiselect) {
        handleItemChange(itemId);
      } else {
        setSelectedItems([itemId]);
        setIsOpen(false);
        onChange([itemId]);
      }
    } else {
      handleItemChange(itemId);
      onChange(itemId);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const formatSelectedNames = () => {
    if (selectedItems?.length === 0 && placeholder === "Select Activity") {
      if (activities && localStorage.getItem("construction-act-id") !== "All" && localStorage.getItem("construction-act-id") !== null && localStorage.getItem("construction-act-id") !== "None Selected") {
        return activities[localStorage.getItem("construction-act-id")]?.name
      }
      else {
        if (localStorage.getItem("construction-act-id") === "All") {
          return "Overall"
        }
        if (localStorage.getItem("construction-act-id") === "None Selected") {
          return "None Selected"
        }
      }
    } else if (multiselect) {
      return selectedItems
        ?.map((itemId) => activities[itemId]?.name)
        .filter(Boolean)
        .join(", ");
    } else {
      if (activities) {
        return activities[selectedItems[0]]?.name || "";
      }
    }
  };

  useEffect(() => {
    if (selectedItems.length > 0 && disabled) {
      setSelectedItems([]);
    }
  }, [disabled]);

  useEffect(() => {
    if (empty === true) {
      setSelectedItems([]);
    }
  }, [empty]);

  const renderActivity = (activity) => {
    const { id, name, parent_activity_id } = activity;
    const isSubActivity = parent_activity_id !== null;
    const marginLeft = (isSubActivity) => {
      return {
        marginLeft: isSubActivity ? "5px" : "",
      };
    };

    const isActive = selectedItems.includes(id);

    const disParentActivity = true;
    let temp = [];


    if (parent_activity_id === null) {
      Object.keys(activities).map((j) => {
        if (activities[j].parent_activity_id === id) {
          temp.push(j);
        }
      });
    }

    let disabled = false;
    if (
      (sub_activities_disabled && parent_activity_id !== null) ||
      (activities_disabled && parent_activity_id === null && temp?.length !== 0)
    ) {
      disabled = true; // Disable selection if sub_activities_disabled is true or activities_disabled is true
    }

    return (
      <Typography
        key={id}
        // className={`flex items-center ${marginLeft} ${
        //   isActive ? "bg-gray-200" : "hover:bg-gray-100"
        // } cursor-pointer ${disabled ? "text-gray-400" : "text-black"}`}
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: isActive ? "#d9d8d7" : "#f7f7f7",
          cursor: "pointer",
          paddingRight: "10px",
          color: disabled ? "gray" : "black",
        }}
        onClick={() => {
          if (!disabled) {
            handleActivityClick(id);
          }
        }}
        disabled={disabled}
        className="listitem"
      >
        {multiselect && (
          <TextField
            disabled={disabled}
            type="checkbox"
            checked={isActive}
            onChange={() => handleItemChange(id)}
            // disabled={activities_disabled}
            sx={{ cursor: "pointer" }}
          />
        )}
        <Typography

          className="listitem"
          sx={{
            marginLeft: "2px",
            display: "flex",
            color: activities_disabled || disabled ? "gray" : "black",
            fontSize: "12px",
          }}
        >

          <Typography
            sx={{ color: activities_disabled && disabled ? "gray" : "black" }}
          >
            <Typography
              sx={{
                paddingLeft: parent_activity_id ? "5px" : "0",
                fontSize: "12px",
                fontWeight:
                  parent_activity_id || activities_disabled ? "normal" : "bold",
              }}
            >
              {name}
            </Typography>
          </Typography>
        </Typography>
      </Typography>
    );
  };

  return (
    <Box
      className="relative"
      ref={dropdownRef}
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <Box>

        <input
          type="text"
          disabled={disabled}
          style={{
            cursor: "pointer",
            width: width,
            padding: "15px 5px",
            fontSize: fontSize,
            fontWeight: textbold ? "700" : null,
            // textbold || activities_disabled === false ? "700" : null,
            border: border,
            backgroundColor: "rgba(105, 111, 121, 0.01)",
            userSelect: "none",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            borderRadius: "8px",

          }}

          placeholder={placeholder}
          onClick={handleFocus}
          value={act === undefined && activities ? formatSelectedNames() : act !== "All" && act !== "None Selected" && activities ? activities[act]?.name : formatSelectedNames()}
          readOnly
        />
        {isOpen && !disabled ? (
          <List
            sx={{
              position: "absolute",
              zIndex: "10",
              backgroundColor: "white",
              paddingY: "1px",
              width: width,
              height: "200px",
              overflowY: "auto",
            }}

          >
            {multiselect ? (
              <ListItem
                sx={{
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#f7f7f7" },
                  backgroundColor: "#f7f7f7",
                  paddingLeft: "15px",
                }}
                onClick={() => handleActivityClick("All")}
                className="lsitem"
              >
                <span value="All">All Activities</span>
              </ListItem>
            ) : null}

            {placeholder === "Select Activity" ? (
              <ListItem
                // className={`cursor-pointer hover:bg-gray-100 pl-2`}
                sx={{
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#f7f7f7" },
                  paddingLeft: "55px",
                  backgroundColor: "#f7f7f7",
                }}
                onClick={() => { handleActivityClick("None Selected"); setIsOpen(!isOpen) }}
                className="lsitem"
              >
                <Typography value="None Selected" sx={{ fontWeight: "600" }}>
                  None Selected
                </Typography>
              </ListItem>
            ) : null}
            {placeholder === "Select Activity" ? (
              <ListItem
                // className={`cursor-pointer hover:bg-gray-100 pl-2`}
                sx={{
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#f7f7f7" },
                  paddingLeft: "55px",
                  backgroundColor: "#f7f7f7",
                }}
                onClick={() => { handleActivityClick("All"); setIsOpen(!isOpen) }}
                className="lsitem"
              >
                <Typography value="OverAll" sx={{ fontWeight: "600" }}>
                  Overall
                </Typography>
              </ListItem>
            ) : null}
            {Object.values(rearrangeData(activities)).map((activity) =>
              renderActivity(activity)
            )}
          </List>
        ) : null}
      </Box>
      <Box>
        {placeholder === "Select Activity" ? (
          <BsChevronDown
            style={{
              margin: "15px 0 0 0",
              cursor: "pointer",
              position: "absolute",
              left: leftAlign,
            }}
            onClick={handleFocus}
          />
        ) : (
          <BsChevronDown
            style={{
              margin: "15px 0 0 0",
              position: "absolute",
              right: "35px",
            }}
            onClick={handleFocus}
          />
        )}
      </Box>
    </Box>
  );
};

export default ActivitySelect;
