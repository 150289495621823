// import {
//   TableHead,
//   TableRow,
//   TableCell,
//   Table,
//   TableBody,
//   TableContainer,
//   TextField,
//   Box,
//   Collapse,
//   CircularProgress,
//   Typography,
//   Paper,
//   Button,
// } from "@mui/material";
// import React, { useState, useEffect, useCallback } from 'react'
// import { ToastContainer, toast } from "react-toastify";
// import dashboardAtom, { fetchExistingDistrbutedInstalledAtom, updateDistributedAndInstalledQuantityAtom } from "../data/dashboardAtom";
// import { useAtom } from "jotai";
// import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
// import { get_distributed_and_installed_bom_for_activity, updated_progress_quantity_for_all_selected_trackerrows } from "./functions/constructionProgress";
// import ConstructionProgressPierTTSelection from "./constructionProgressPierTTSelection";
// import SteppedModal from "./constructionInprogressSteppedModal";

// const ConstructionInprogressTable = ({ isZimmerman, selectedTrackerRows, selectedTrackerDetails, selectedActivity, bulkPiersTTData, constructionProgressTrackerrowWise }) => {
//   const [dashboard] = useAtom(dashboardAtom)
//   const [, updateDistributedAndInstalledQuantity] = useAtom(updateDistributedAndInstalledQuantityAtom)
//   const [, fetchExistingDistrbutedInstalled] = useAtom(fetchExistingDistrbutedInstalledAtom)

//   const [currentTrackerTypeId, setCurrentTrackerTypeId] = useState(0)
//   const [selectedTrackerTypeId, setSelectedTrackerTypeId] = useState(null)
//   const [openRows, setOpenRows] = useState({});
//   const [bomDetailss, setBomDetailss] = useState([])
//   const [updateQuantity, setUpdateQuantity] = useState({});
//   const [innerTableLoading, setInnerTableLoading] = useState({});
//   const [openModal, setModalOpen] = useState(false)
//   const [piersTorqueTubesDetails, setPiersTorqueTubesDetails] = useState({})
//   const [trackerRows, setTrackerRows] = useState([])
//   const [openSteppedModal, setOpenSteppedModal] = useState(false);
//   const [selectedCurrentRow, setSelectedCurrentRow] = useState(null);
//   const [blockDetails, setBlockDetails] = useState({});
//   const [trackerTypeDetails, setTrackerTypeDetails] = useState({});


//   useEffect(() => {
//     if (selectedCurrentRow && dashboard?.trackerTypeNames) {
//       const trackerTypeId = parseInt(selectedCurrentRow.id);
//       const trackerTypeDetails = dashboard.trackerTypeNames.find(
//         (j) => j?.id === trackerTypeId
//       );
//       setTrackerTypeDetails(trackerTypeDetails);

//       // Set block details (you might need to adjust this based on your data structure)
//       if (bulkPiersTTData?.piers) {
//         const trackerRowId = parseInt(selectedCurrentRow.id.split("-")[1]);
//         if (bulkPiersTTData.piers[trackerRowId] && bulkPiersTTData.piers[trackerRowId].length > 0) {
//           setBlockDetails(bulkPiersTTData.piers[trackerRowId][0]);
//         }
//       }
//     }
//   }, [selectedCurrentRow, dashboard?.trackerTypeNames, bulkPiersTTData]);


//   const handleOpenSteppedModal = useCallback((row) => async () => {
//     setSelectedCurrentRow(row);
//     setInnerTableLoading(prev => ({ ...prev, [row.id]: true }));

//     let groupData = groupByTrackerType();
//     let currentSelectedTrackerTypes = groupData[row.id];
//     let bomData = await get_distributed_and_installed_bom_for_activity(
//       selectedActivity,
//       currentSelectedTrackerTypes[0],
//       dashboard?.distributedInstalledBom,
//       dashboard?.total_installed_distributed,
//       dashboard?.existing_bom_per_tracker_row,
//       isZimmerman,
//       dashboard?.zimmermanTableWisePierPartBom
//     );

//     setBomDetailss(prev => ({ ...prev, [row.id]: bomData }));
//     setInnerTableLoading(prev => ({ ...prev, [row.id]: false }));
//     setTrackerRows(currentSelectedTrackerTypes); // Set the trackerRows here
//     setOpenSteppedModal(true);
//   }, [groupByTrackerType, selectedActivity, dashboard, isZimmerman, get_distributed_and_installed_bom_for_activity]);

//   const handleCloseSteppedModal = useCallback(() => {
//     setOpenSteppedModal(false);
//     setSelectedCurrentRow(null);
//   }, []);

//   const handleOpenModal = useCallback((trackerId) => () => {
//     setSelectedTrackerTypeId(trackerId);
//     setModalOpen(true);
//   }, []);

//   const handleCloseModal = useCallback(() => {
//     setModalOpen(false);
//     setSelectedTrackerTypeId(null);
//   }, []);

//   useEffect(() => {
//     if (openModal && selectedTrackerTypeId) {
//       const result = pierTtDetails(selectedTrackerTypeId);
//       if (result) {
//         setPiersTorqueTubesDetails(result.pierTTObj);
//         setTrackerRows(result.selectedTrackerTypeTrackerRows);
//       }
//     }
//   }, [openModal, selectedTrackerTypeId]);

//   const pierTtDetails = (trackerTypeId) => {
//     if (bulkPiersTTData) {
//       let pierTTObj = {}
//       let groupedTrackerRows = groupByTrackerType()
//       let selectedTrackerTypeTrackerRows = groupedTrackerRows[trackerTypeId]
//       let trackerrowId = parseInt(selectedTrackerTypeTrackerRows[0]?.split("-")[1])
//       let piersTorqueTubeData = bulkPiersTTData
//       if (piersTorqueTubeData["piers"][trackerrowId] && piersTorqueTubeData["tt"][trackerrowId]) {
//         pierTTObj["piers"] = piersTorqueTubeData["piers"][trackerrowId]
//         pierTTObj["torque_tube"] = piersTorqueTubeData["tt"][trackerrowId]
//         return { pierTTObj, selectedTrackerTypeTrackerRows };
//       }
//     }
//     return null;
//   };


//   const handleToggleRow = async (trackerTypeId) => {
//     setInnerTableLoading(prev => ({ ...prev, [trackerTypeId]: true }));
//     setOpenRows(prev => ({
//       ...prev,
//       [trackerTypeId]: !prev[trackerTypeId]
//     }));
//     setCurrentTrackerTypeId(parseInt(trackerTypeId))
//     let groupData = groupByTrackerType()
//     let currentSelectedTrackerTypes = groupData[trackerTypeId]
//     let bomData = await get_distributed_and_installed_bom_for_activity(selectedActivity, currentSelectedTrackerTypes[0], dashboard?.distributedInstalledBom, dashboard?.total_installed_distributed, dashboard?.existing_bom_per_tracker_row, isZimmerman, dashboard?.zimmermanTableWisePierPartBom)
//     setBomDetailss(prev => ({ ...prev, [trackerTypeId]: bomData }));
//     setInnerTableLoading(prev => ({ ...prev, [trackerTypeId]: false }));
//   };

//   useEffect(() => {
//     init()
//     groupByTrackerType()
//   }, [])

//   const init = async () => {
//     try {
//       await fetchExistingDistrbutedInstalled()
//     } catch (err) {
//       console.log(err)
//     }
//   }

//   function groupByTrackerType() {
//     const groupedData = {};

//     selectedTrackerRows?.forEach(row => {
//       // Split the string to get the tracker type ID (second index in the array)
//       const trackerTypeId = parseInt(row?.split('-')[2]);

//       // If the key (trackerTypeId) already exists in the object, push the row string to the array
//       if (groupedData[trackerTypeId]) {
//         groupedData[trackerTypeId].push(row);
//       } else {
//         // Otherwise, create a new array with the row string
//         groupedData[trackerTypeId] = [row];
//       }
//     });

//     return groupedData;
//   }

//   const handleQtyOnChange = (e, item, maxVal, type) => {
//     const trackerTypeId = parseInt(item?.tracker_type_id)
//     const value = e.target.value;
//     if (parseInt(value) > maxVal) {
//       toast.error("Please enter a value less than or equal to the total quantity");
//       return;
//     }
//     if (value !== "" && parseInt(value) <= maxVal) {
//       setUpdateQuantity(prev => ({
//         ...prev,
//         [trackerTypeId]: {
//           ...prev[trackerTypeId],
//           [`${item.bom_id}_${item.tracker_row_id}`]: {
//             ...prev[trackerTypeId]?.[`${item.bom_id}_${item.tracker_row_id}`],
//             [type]: parseInt(value)
//           }
//         }
//       }));
//     }
//   };
//   // useEffect(() => {
//   //   setUpdateQuantity({})
//   //   updateDistributedAndInstalledQuantity({})
//   // }, [clearTableData])

//   useEffect(() => {
//     const updateQuantities = async () => {
//       const groupedData = groupByTrackerType();
//       let allUpdates = {};
//       for (const [trackerTypeId, quantities] of Object.entries(updateQuantity)) {
//         const updatedForTrackerType = await updated_progress_quantity_for_all_selected_trackerrows(
//           quantities,
//           groupedData[trackerTypeId]
//         );
//         allUpdates = { ...allUpdates, ...updatedForTrackerType };
//       }
//       if (Object.keys(allUpdates).length > 0) {
//         await updateDistributedAndInstalledQuantity(allUpdates);
//       }
//     };

//     updateQuantities();
//   }, [updateQuantity]);

//   const getExistingDistributedAndInstalledQuantity = (data) => {
//     let existingValue = null
//     let bomId_trackerrowId_key = `${parseInt(data?.bom_id)}_${parseInt(data?.tracker_row_id)}`
//     if (dashboard?.existing_bom_per_tracker_row[bomId_trackerrowId_key]) {
//       if (dashboard?.existing_bom_per_tracker_row[bomId_trackerrowId_key][data?.found_type] !== 0) {
//         existingValue = dashboard?.existing_bom_per_tracker_row[bomId_trackerrowId_key][data?.found_type]
//       }
//     }
//     return existingValue
//   }

//   const getCurrentDistributedAndInstalledQuantity = (data) => {
//     let currentValue = null
//     let key = `${data?.bom_id}_${parseInt(data?.tracker_row_id)}`
//     if (Object.keys(dashboard?.updateDistributedAndInstalledQuantity).length > 0) {
//       if (dashboard?.updateDistributedAndInstalledQuantity[key] && dashboard?.updateDistributedAndInstalledQuantity[`${data?.bom_id}_${parseInt(data?.tracker_row_id)}`][data?.found_type])
//         currentValue = dashboard?.updateDistributedAndInstalledQuantity[key][data?.found_type]
//     }
//     return currentValue
//   }




//   const renderTable = (data, type) => (
//     <Box>
//       <Table size="small" sx={{ marginBottom: 2 }}>
//         <TableHead>
//           <TableRow>
//             <TableCell colSpan={4}>
//               <Typography variant="subtitle1">
//                 {type.charAt(0).toUpperCase() + type.slice(1)} Quantities
//               </Typography>
//             </TableCell>
//           </TableRow>
//           <TableRow>
//             <TableCell sx={{ backgroundColor: "rgb(254 202 202)", width: '20%' }}>Part Number</TableCell>
//             <TableCell sx={{ backgroundColor: "rgb(254 202 202)", width: '50%' }}>Part Description</TableCell>
//             <TableCell sx={{ backgroundColor: "rgb(254 202 202)", width: '15%' }}>Quantities Per Tracker</TableCell>
//             <TableCell sx={{ width: '15%' }}>{type.charAt(0).toUpperCase() + type.slice(1)} Quantities</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {data.map((item) => (
//             <TableRow key={item?.bom_id}>
//               <TableCell>{item?.part_name}</TableCell>
//               <TableCell>{item?.description}</TableCell>
//               <TableCell>{item?.total_quantity}</TableCell>
//               <TableCell>
//                 <TextField
//                   name="qty"
//                   defaultValue={dashboard?.updateDistributedAndInstalledQuantity[`${item?.bom_id}_${parseInt(item?.tracker_row_id)}`] && dashboard?.updateDistributedAndInstalledQuantity[`${item?.bom_id}_${parseInt(item?.tracker_row_id)}`][item?.found_type] ? getCurrentDistributedAndInstalledQuantity(item) : getExistingDistributedAndInstalledQuantity(item)}
//                   onChange={(e) => handleQtyOnChange(e, item, item?.total_quantity, type)}
//                   sx={{ width: 160 }}
//                   InputProps={{
//                     inputProps: {
//                       type: "number",
//                       max: item?.total_quantity,
//                       min: 0,
//                     },
//                   }}
//                 />
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>

//     </Box>
//   );
//   const InnerTable = ({ row }) => {
//     const isOpen = openRows[row.id] || false;


//     return (
//       <>
//         <TableRow>
//           {/* <TableCell>{row?.id}</TableCell> */}
//           <TableCell>{row?.tracker_type}</TableCell>
//           <TableCell>{row?.count}</TableCell>
//           <TableCell>
//             <Button variant="contained" sx={{ textTransform: "none" }} onClick={handleOpenSteppedModal(row)}>Mark Progress</Button></TableCell>
//           {/* <TableCell>
//             <IconButton size="small" onClick={() => handleToggleRow(row?.id)}>
//               {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
//             </IconButton>
//           </TableCell> */}
//         </TableRow>
//         <TableRow>
//           <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
//             <Collapse in={isOpen} timeout="auto" unmountOnExit>
//               <Box margin={1}>
//                 {innerTableLoading[row.id] ? (
//                   <CircularProgress />
//                 ) : (
//                   <>
//                     {bomDetailss[row?.id]?.distributed && renderTable(bomDetailss[row?.id]?.distributed, "distributed")}
//                     {bomDetailss[row?.id]?.installed && renderTable(bomDetailss[row?.id]?.installed, "installed")}
//                   </>
//                 )}

//               </Box>
//             </Collapse>
//           </TableCell>
//         </TableRow>
//       </>
//     );
//   };


//   const innerTableComponent = (
//     <Box>
//       {selectedCurrentRow && (
//         <>
//           {innerTableLoading[selectedCurrentRow.id] ? (
//             <CircularProgress />
//           ) : (
//             <>

//               {bomDetailss[selectedCurrentRow.id]?.distributed && (
//                 <>
//                   <Typography variant="h6">Distributed Quantities</Typography>
//                   {renderTable(bomDetailss[selectedCurrentRow.id].distributed, "distributed")}
//                 </>
//               )}
//               {bomDetailss[selectedCurrentRow.id]?.installed && (
//                 <>
//                   <Typography variant="h6">Installed Quantities</Typography>
//                   {renderTable(bomDetailss[selectedCurrentRow.id].installed, "installed")}
//                 </>
//               )}
//               {!bomDetailss[selectedCurrentRow.id]?.distributed && !bomDetailss[selectedCurrentRow.id]?.installed && (
//                 <Typography>No data available for this tracker type.</Typography>
//               )}
//               <Box>


//               </Box>
//             </>
//           )}
//         </>
//       )}
//     </Box>
//   );

//   return (
//     <Box>
//       <Box>
//         <TableContainer component={Paper}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 {/* <TableCell>Tracker Type Id</TableCell> */}
//                 <TableCell>Tracker Type Name</TableCell>
//                 <TableCell>Quantities</TableCell>
//                 <TableCell />
//                 {/* <TableCell /> */}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {selectedTrackerDetails?.map((row) => (
//                 <InnerTable key={row.id} row={row} />
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//       <Box>
//         <SteppedModal
//           open={openSteppedModal}
//           handleClose={handleCloseSteppedModal}
//           innerTableComponent={innerTableComponent}
//           piersTorqueTubesDetails={piersTorqueTubesDetails}
//           trackerRows={trackerRows}
//           constructionProgressTrackerrowWise={constructionProgressTrackerrowWise}
//           blockDetails={blockDetails}
//           trackerTypeDetails={trackerTypeDetails}
//         />

//       </Box>
//       {/* {openModal && (
//         <ViewTrackerModal
//           open={openModal}
//           handleClose={handleCloseModal}
//           piersTorqueTubesDetails={piersTorqueTubesDetails}
//           trackerRows={trackerRows}
//           constructionProgressTrackerrowWise={constructionProgressTrackerrowWise}
//         />)} */}

//     </Box>
//   );
// }

// export default ConstructionInprogressTable



import {
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from "react-toastify";
import dashboardAtom, { fetchExistingDistrbutedInstalledAtom, updateDistributedAndInstalledQuantityAtom } from "../data/dashboardAtom";
import { useAtom } from "jotai";
import { updated_progress_quantity_for_all_selected_trackerrows } from "./functions/constructionProgress";

const ConstructionInprogressTable = ({ rows, foundType, bomDetails, trackerrowId, isZimmerman, clearTableData, selectedTrackerRows }) => {
  const [dashboard] = useAtom(dashboardAtom)
  const [btnenable, setbtnenable] = useState(false);
  const [updateQuantity, setUpdateQuantity] = useState({});
  const [, updateDistributedAndInstalledQuantity] = useAtom(updateDistributedAndInstalledQuantityAtom)
  const [, fetchExistingDistrbutedInstalled] = useAtom(fetchExistingDistrbutedInstalledAtom)

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    try {
      await fetchExistingDistrbutedInstalled()
    } catch (err) {
      console.log(err)
    }
  }

  // const handleQtyOnChange = (e, item, maxVal, type) => {
  //   const value = e.target.value;
  //   if (parseInt(value) > maxVal) {
  //     toast.error("Please enter a value less than or equal to the total quantity");
  //     return;
  //   }
  //   if (value !== "" && parseInt(value) <= maxVal) {

  //     setUpdateQuantity(prev => ({
  //       ...prev,
  //       [`${item.bom_id}_${item.tracker_row_id}`]: {
  //         ...prev[`${item.bom_id}_${item.tracker_row_id}`],
  //         [type]: parseInt(value)
  //       }

  //     }));
  //   }

  // };
 
  const handleQtyOnChange = (e, i, maxVal, type) => {
    if (e.target.value !== "") {
      if (e.target.value > 0) {
        setbtnenable(true);
      }
      if (parseInt(e.target.value) > maxVal) {
        toast.error("Please enter the value lesser than total qty");
      }
      let key = `${i.bom_id}_${trackerrowId}`;
      let progress_qty = { ...dashboard?.updateDistributedAndInstalledQuantity };
      if (Object.keys(dashboard?.updateDistributedAndInstalledQuantity).length > 0 && progress_qty[key]) {
        if (progress_qty[key][`${type}`]) {
          progress_qty[key] = { ...progress_qty[key], [type]: parseInt(e.target.value) };
        } else {
          let pqty_type = { ...progress_qty[key] };
          pqty_type[`${type}`] = parseInt(e.target.value);
          progress_qty[key] = pqty_type;
        }
      } else {
        let pqty_type = {};
        pqty_type[type] = parseInt(e.target.value);
        progress_qty[key] = pqty_type;
      }
      setUpdateQuantity(progress_qty);
    }
  };

  useEffect(() => {
    setUpdateQuantity({})
    updateDistributedInstalledQuantity()
  }, [clearTableData])

  useEffect(() => {
    if (Object.keys(updateQuantity).length > 0) {
      updateDistributedInstalledQuantity()
    }
  }, [updateQuantity])

  const updateDistributedInstalledQuantity = async () => {
    // let updatedDistributedInstalledQtyForAllSelectedTrackers = await updated_progress_quantity_for_all_selected_trackerrows(updateQuantity, selectedTrackerRows)
    await updateDistributedAndInstalledQuantity(updateQuantity)
  }


  const getExistingDistributedAndInstalledQuantity = (data) => {
    let existingValue = null
    let bomId_trackerrowId_key = `${parseInt(data?.bom_id)}_${parseInt(data?.tracker_row_id)}`
    if (dashboard?.existing_bom_per_tracker_row[bomId_trackerrowId_key]) {
      if (dashboard?.existing_bom_per_tracker_row[bomId_trackerrowId_key][data?.found_type] !== 0) {
        existingValue = dashboard?.existing_bom_per_tracker_row[bomId_trackerrowId_key][data?.found_type]
      }
    }
    return existingValue
  }

  const getCurrentDistributedAndInstalledQuantity = (data) => {
    let currentValue = null
    let key = `${data?.bom_id}_${parseInt(data?.tracker_row_id)}`
    if (Object.keys(dashboard?.updateDistributedAndInstalledQuantity).length > 0) {
      if (dashboard?.updateDistributedAndInstalledQuantity[key] && dashboard?.updateDistributedAndInstalledQuantity[`${data?.bom_id}_${parseInt(data?.tracker_row_id)}`][data?.found_type])
        currentValue = dashboard?.updateDistributedAndInstalledQuantity[key][data?.found_type]
    }
    return currentValue
  }

  return (
    <TableContainer sx={{ height: foundType === "distributed_installed" ? "245px" : null, overflow: "auto" }}>
      <Table sx={{ padding: "10px" }} size="small">
        <TableHead>
          {rows.map((j) => {
            return (
              <TableRow>
                <TableCell sx={{ backgroundColor: "rgb(254 202 202)" }}>{j?.part_number}</TableCell>
                <TableCell width={550} sx={{ backgroundColor: "rgb(254 202 202)" }}>{j?.part_description}</TableCell>
                <TableCell sx={{ backgroundColor: "rgb(254 202 202)" }}>{j?.total_quantity}</TableCell>
                <TableCell width={160}>{j?.type}</TableCell>
              </TableRow>
            )
          })}
        </TableHead>
        <TableBody>
          {bomDetails?.map((i) => {
            return (
              <TableRow>
                <TableCell>{i?.part_name}</TableCell>
                <TableCell width={550}>{i?.description}</TableCell>
                <TableCell >{i?.total_quantity}</TableCell>
                <TableCell>
                  <TextField
                    name="qty"
                    // defaultValue={i?.
                    //   found_type === "installed" ?
                    //   (i?.existing_installed_quantity !== 0 ? i?.existing_installed_quantity : null) : (i?.existing_distributed_quantity !== 0 ? i?.existing_distributed_quantity : null)}

                    defaultValue={selectedTrackerRows?.length === 1 ? dashboard?.updateDistributedAndInstalledQuantity[`${i?.bom_id}_${parseInt(i?.tracker_row_id)}`] && dashboard?.updateDistributedAndInstalledQuantity[`${i?.bom_id}_${parseInt(i?.tracker_row_id)}`][i?.found_type] ? getCurrentDistributedAndInstalledQuantity(i) : getExistingDistributedAndInstalledQuantity(i) : ""}
                    onChange={(e) => {
                      handleQtyOnChange(
                        e,
                        i,
                        i?.total_quantity,
                        i?.found_type
                      );
                    }}
                    sx={{
                      // ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      width: 160,
                    }}
                    InputProps={{
                      inputProps: {
                        type: "number",
                        max: i?.total_quantity,
                        min: 0,
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ConstructionInprogressTable
