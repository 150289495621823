import React, { useEffect, useState } from 'react';
import { Modal, Box, Stepper, Step, StepLabel, StepButton, Button, Typography, Divider } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import ConstructionProgressPierTTSelection from "./constructionProgressPierTTSelection";

const SteppedModal = ({ open, handleClose, innerTableComponent, piersTorqueTubesDetails, trackerRows, enableNextButton, enableSaveButton, onCloseWithoutSaving, selectedTrackerDetails, inprogressCount,isQtyExceeded }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [clearData, setClearData] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const steps = ['Enter BOM data', 'Enter Colors'];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        // Disable step navigation by clicking
        if (step === activeStep) {
            setActiveStep(step);
        }
    };

    useEffect(() => {
        if (open === false) {
            setActiveStep(0)
        }
    }, [open])

    const handleSave = () => {
        handleClose();
    };

    const handleCloseModal = () => {
        setConfirmationOpen(true);
    };

    const handleConfirmClose = () => {
        if (selectedTrackerDetails && selectedTrackerDetails.id) {
            onCloseWithoutSaving(selectedTrackerDetails.id);
        } else {
            console.error("Selected tracker details or ID is missing");
        }
        setConfirmationOpen(false);
        handleClose();
    };

    const handleCancelClose = () => {
        setConfirmationOpen(false);
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "90vh",
                    width: "80vw",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 2,
                        borderBottom: '1px solid #e0e0e0',
                    }}>
                        <Typography variant="h6" component="h2" >
                            {selectedTrackerDetails?.tracker_type}
                        </Typography>
                        <CloseIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={handleCloseModal}
                        />
                    </Box>
                    <Stepper activeStep={activeStep} sx={{ py: 2, px: 20, borderBottom: '1px solid #e0e0e0' }}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton onClick={handleStep(index)} disabled={index !== activeStep}>
                                    <StepLabel
                                        StepIconProps={{
                                            icon: index + 1,
                                            active: activeStep === index,
                                            completed: false, // This ensures the tick is never shown
                                        }}
                                        sx={{
                                            '& .MuiStepLabel-label': {
                                                color: activeStep === index ? 'text.primary' : 'text.disabled',
                                                fontWeight: activeStep === index ? 'bold' : 'normal',
                                            },
                                        }}
                                    >
                                        {label}
                                    </StepLabel>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        ...(activeStep === 0 ? {
                            overflow: 'auto',
                        } : {
                            overflow: 'hidden',
                        })

                    }}>
                        {activeStep === 0 ? (
                            <Box sx={{ p: 2, height: '100%', overflow: 'auto' }}>
                            {innerTableComponent}
                        </Box>
                           
                        ) : (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                overflow: 'hidden',
                            }}>
                                <Typography sx={{ p: 2 }}>Choose piers and bays that are completed</Typography>
                                <Box sx={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    overflow: 'hidden'  // Hide overflow
                                }}>
                                    <ConstructionProgressPierTTSelection
                                        pierTorqueTubeData={piersTorqueTubesDetails}
                                        selectedTrackerrow={trackerRows}
                                        activityId={localStorage.getItem("construction-act-id")}
                                        clearState={clearData}
                                    />
                                </Box>
                            </Box>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, borderTop: '1px solid #e0e0e0' }}>
                        {activeStep === 1 && (
                            <Button onClick={handleBack} variant='contained' size='large' sx={{ textTransform: "none" }} >
                                Back
                            </Button>
                        )}
                        <Box sx={{ marginLeft: 'auto' }}>
                            {activeStep === steps.length - 1 ? (
                                <Button onClick={handleSave} variant='contained' disabled={!enableSaveButton} size='large' sx={{ textTransform: "none" }}>Save</Button>
                            ) : (
                                <Button
                                    onClick={handleNext}
                                    disabled={!enableNextButton || isQtyExceeded || inprogressCount === 0}
                                    variant='contained'
                                    size='large'
                                    sx={{ textTransform: "none" }}
                                >
                                    Next
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={confirmationOpen}
                onClose={handleCancelClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 670,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>

                    <Typography id="alert-dialog-description" sx={{ mb: 3, fontSize: "20px" }}>
                    All the entered data within this tracker type will be lost, please confirm
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                        <Button onClick={handleCancelClose} variant="outlined" sx ={{textTransform:"none"}}>
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmClose} variant="contained" autoFocus sx ={{textTransform:"none"}}>
                            Confirm
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default SteppedModal;