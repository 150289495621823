import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useCallback, useRef } from "react";
import ConstructionProgressHeader from "./header";
import dashboardAtom, {
  fetchBlockAtom,
  fetchSvgBlockProgressActivityWiseAtom,
  fetchIsAllTrackersSelectedAtom,
  fetchConstructionProgressForBlockAtom,
  fetchTrackerTypeNamesAtom,
  updateIsMultipleBlocksSelectionEnabledAtom,
  updateSelectedBlocksInSVGAtom,
} from "../../data/dashboardAtom";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import mapAtom, { fetchSvgMapAtom } from "../../data/svgMapAtom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Header from "../../components/appBar";
import activitiesAtom, { fetchActivitiesAtom } from "../../data/activitiesAtom";
import { GrCircleInformation } from "react-icons/gr";
import projectAtom, { fetchProjectDetailsAtom } from "../../data/projectAtom";
import syncStatusAtom from "../../data/syncStatusAtom";
import { constructionOverallProgressOfAllBlocksForProject } from "../../components/functions/constructionProgress";
import ScrollableTabsButtonForce from "./tabs";
import { IS_PROJECT_TYPE_ZIMMERMAN_FLAG } from "../../featureFlags/featureFlags";
import { debounce } from 'lodash';
import produce from "immer";
import { isOnline } from "../../dal/checkIsOnline";


const useLatest = (value) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref;
};

const Home = () => {
  const [dashboard] = useAtom(dashboardAtom);
  const [activities] = useAtom(activitiesAtom);
  const [activityId, setActivityId] = useState(localStorage.getItem("construction-act-id"));
  const [mapdata] = useAtom(mapAtom);
  const [projects] = useAtom(projectAtom);
  const [, fetchActivities] = useAtom(fetchActivitiesAtom);
  const [, isAllTrackersSelected] = useAtom(fetchIsAllTrackersSelectedAtom)
  const [, fetchProjects] = useAtom(fetchProjectDetailsAtom);
  const [, fetchmapData] = useAtom(fetchSvgMapAtom);
  const [, fetchBlockData] = useAtom(fetchBlockAtom);
  const [, fetchTrackerTypeName] = useAtom(fetchTrackerTypeNamesAtom);
  const [, fetchSvgBlockProgressActivityWise] = useAtom(fetchSvgBlockProgressActivityWiseAtom);
  const [syncStatusData] = useAtom(syncStatusAtom);
  const [, fetchConstructionProgressForBlock] = useAtom(fetchConstructionProgressForBlockAtom)
  const [, updateSelectedBlocksInSVG] = useAtom(updateSelectedBlocksInSVGAtom)
  const [isZimmerman, setIsZimmerman] = useState(false)
  const [isBlocksSelectEnabled, setIsBlocksSelectEnabled] = useState(false);
  const isBlocksSelectEnabledRef = useLatest(isBlocksSelectEnabled);
  const [selectedBlockIds, setSelectedBlockIds] = useState([])
  const selectedBlockIdsRef = useLatest(selectedBlockIds);

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("punchlist-visibility-index", 0)
    localStorage.setItem("tab-index", 0)
    init();
  }, [syncStatusData.syncing]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    async function fetchProjectTypeFlag() {
      let is_zimmeran = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
      setIsZimmerman(is_zimmeran)
    }
    fetchProjectTypeFlag();
  }, [])

  const init = async () => {
    try {
      await fetchBlockData();
      await fetchmapData();
      await fetchSvgBlockProgressActivityWise();
      await fetchActivities()
      await fetchProjects()
      await isAllTrackersSelected(false)
      await fetchConstructionProgressForBlock()
      await fetchTrackerTypeName()
    } catch (err) {
      console.log(err);
    }
  };

  const data_from_child = (data) => {
    if (data === "All" || data === "None Selected") {
      setActivityId(data);
    } else {
      setActivityId(data);
    } // or set the data to a state
  };

  // activityWiseSvgBlock CP progress
  const activityWiseSvgBlockProgress = (data) => {
    let temp = 0
    if(localStorage.getItem("construction-act-id") !== "None Selected"){
    if (dashboard?.svgBlockProgressActivityWise && dashboard?.svgBlockProgressActivityWise[parseInt(activityId)]) {
      for (let j of dashboard?.svgBlockProgressActivityWise[parseInt(activityId)]) {
        if (j?.block_id === data) {
          temp = Math.floor(j?.progress);
        }
      }
    }
  }
    if (isNaN(temp)) {
      temp = 0
    }
    
    return temp;
  };

  useEffect(() => {
    updateSelectedBlocks()
  }, [selectedBlockIds])

  const updateSelectedBlocks = async () => {
    await updateSelectedBlocksInSVG(selectedBlockIds)
  }

  const handleBlockClick = useCallback(debounce((block, block_svg_id) => {
    if (isBlocksSelectEnabledRef.current) {
      setSelectedBlockIds((prevSelectedBlockIds) => {
        const newSelectedBlockIds = new Set(prevSelectedBlockIds);
        if (newSelectedBlockIds.has(block.id)) {
          newSelectedBlockIds.delete(block.id);
          block_svg_id.style.fill = ""; // Reset the color if deselected
        } else {
          newSelectedBlockIds.add(block.id);
          block_svg_id.style.fill = "yellow"; // Set color if selected
        }
        return Array.from(newSelectedBlockIds);
      });
    } else {
      block_svg_id.style.fill = "";
    }

    if (!isBlocksSelectEnabledRef.current && selectedBlockIdsRef.current.length === 0) {
      navigate(`/home/gpslocation/${block.id}/dashboard`);
    }
  }, 100), []);

  const svgBlocksSelect = useCallback(() => {
    if (dashboard?.block?.length > 0) {
      let block_svg_id;
      let block_progress_id;
      let constructionProgressOfAllActivitiesInBlock = constructionOverallProgressOfAllBlocksForProject(activities?.construction, dashboard?.constructionProgressForBlock);

      dashboard.block.forEach((block) => {
        if (isZimmerman) {
          block_svg_id = document.getElementById(block.data.name);
          block_progress_id = document.getElementById(block.data.progress_svg_id);
        } else {
          block_svg_id = document.getElementById(block.data.block_svg_id);
          block_progress_id = document.getElementById(block.data.progress_svg_id);
        }

        const handleClick = () => handleBlockClick(block, block_svg_id);

        if (block_svg_id) {
          block_svg_id.removeEventListener('click', handleClick);
          block_svg_id.addEventListener('click', handleClick);
        }

        if (isBlocksSelectEnabledRef.current) {
          if (selectedBlockIdsRef.current.includes(block.id)) {
            block_svg_id.style.fill = "yellow";
          }
          else {
            block_svg_id.style.fill = ""; // Reset the color if not selected
          }
        } else {

          if (block_svg_id) {

            const updateProgress = (progress) => {
              if (block_progress_id) {
                block_progress_id.querySelector("tspan").innerHTML = `${progress} %`;
              }
              if (progress === 0) {
                block_svg_id.style.fill = "#FFFFFF";
              } else if (progress === 100) {
                block_svg_id.style.fill = "#B4F8D7";
              } else {
                block_svg_id.style.fill = "#FCDDD9";
              }
            };

            if (activityId !== "None Selected" && activityId === "All" && constructionProgressOfAllActivitiesInBlock.length !== 0) {
              const progress = constructionProgressOfAllActivitiesInBlock[block.id];
              updateProgress(progress);
            } else {
              const progress = activityWiseSvgBlockProgress(block.id);
              updateProgress(progress);
            }
          }
        }
      });
    }
  }, [dashboard, activities, isZimmerman, activityId, navigate]);

  useEffect(() => {
    svgBlocksSelect();
    return () => {
      dashboard?.block?.forEach(block => {
        let block_svg_id;
        if (isZimmerman) {
          block_svg_id = document.getElementById(block.data.name);
        } else {
          block_svg_id = document.getElementById(block.data.block_svg_id);
        }
        if (block_svg_id) {
          const handleClick = () => { }; // Dummy function to remove actual listener
          block_svg_id.removeEventListener('click', handleClick);
        }
      });
    };
  }, [dashboard?.block, activityId, isBlocksSelectEnabled, selectedBlockIds]);



  const handleEnableSelectBlocks = async () => {
    setIsBlocksSelectEnabled((prev) => !prev);
    if (isBlocksSelectEnabled) {
      setSelectedBlockIds([]);
    }
  };
  const handleSelectAllBlocks = () => {
    const allBlockIds = dashboard?.block.map(block => block.id);
    setSelectedBlockIds(allBlockIds);
  };

  // Dimensions to place svg
  const getDimension = () => {
    let projectId = JSON.parse(localStorage.getItem("project_id"))
    let temp = {}
    for (let i of projects?.projectDetailsDb) {
      if (i?.id === projectId) {
        temp = i?.data?.svg_dimension?.tablet
      }
    }
    return temp
  }

  return (
    <Box>
      <Header />
      <Grid container>
        <Grid item md={8.2}>
          <Box>
            <ConstructionProgressHeader setter={data_from_child} />
            <Box>
              <Button onClick={handleEnableSelectBlocks} variant="contained" sx={{ margin: "10px" }}>{isBlocksSelectEnabled === true ? "Clear Selection" : "Select Blocks"}</Button>
              {isBlocksSelectEnabled === true &&
                <Button onClick={handleSelectAllBlocks} variant="contained">Select All</Button>}
            </Box>
            <Box
              sx={{
                height: "600px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TransformWrapper
                minScale={parseFloat(getDimension().tab_svg_min_scale)}
                initialScale={parseFloat(getDimension().tab_svg_initial_scale)}
                initialPositionX={((window.innerWidth - 300) - (parseFloat(getDimension().tab_svg_initial_scale) * getDimension().tab_svg_height)) / 2}
                initialPositionY={((window.innerHeight - 300) - (parseFloat(getDimension().tab_svg_initial_scale) * getDimension().tab_svg_height)) / 2}>
                <TransformComponent
                  contentStyle={{
                    height: `${getDimension().tab_svg_height}px`,
                    width: `${getDimension().tab_svg_width}px`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                  }}
                  wrapperStyle={{ height: "100%", width: "100%" }}
                >
                  <div
                    className="svgmap"
                    dangerouslySetInnerHTML={{ __html: mapdata }}
                  ></div>
                </TransformComponent>
              </TransformWrapper>
            </Box>
            {/* <Box sx={{
              display: "flex",
              alignItems: "center",
              position: "absolute",
              bottom: "50px",
              left: "12%",
            }}>
              <GrCircleInformation style={{ padding: "0px 8px" }} fontSize={18} />
              <Typography sx={{ color: "rgb(156 163 175)", fontSize: "13px" }}>Select a block to enter Construction Progress and Quality Check data</Typography>
            </Box> */}
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                bottom: "20px",
                left: "16%",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    height: "25px",
                    width: "25px",
                    borderRadius: "20px",
                    backgroundColor: "#B4F8D7",
                  }}
                ></Box>
                <Typography sx={{ padding: "0px 10px 0px 10px" }}>
                  Completed
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    height: "25px",
                    width: "25px",
                    borderRadius: "20px",
                    backgroundColor: "#FCDDD9",
                  }}
                ></Box>
                <Typography sx={{ padding: "0px 10px 0px 10px" }}>
                  In Progress
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    height: "20px",
                    width: "20px",
                    borderRadius: "20px",
                    backgroundColor: "white",
                    border: "1px solid black",
                  }}
                ></Box>
                <Typography sx={{ padding: "0px 10px 0px 10px" }}>
                  Not Started
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={3.8}>
          <Box sx={{ borderLeft: "1px solid rgba(105, 111, 121, 0.5)" }}>
            <ScrollableTabsButtonForce />
          </Box>
        </Grid>
      </Grid>
    </Box >
  );
};

export default Home;