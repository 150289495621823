import { IS_PROJECT_TYPE_ZIMMERMAN_FLAG } from "../featureFlags/featureFlags";
import suryadb from "../indexedDB/module";
import axios from "axios";

export async function insert_blocks(blockids) {
    for (let i of blockids) {
        await suryadb().get_blocks.put({ id: i?.id, data: i });
    }

}

export async function insert_svg_map() {
    if (!localStorage.getItem("get-data-initially")) {
        let get_svg = await axios.get("/projects/svg_details");
        try {
            let download_svg_file = await axios.get(
                `/file_upload/download?id=${get_svg.data.upload_id}`
            );
            let res = await fetch(download_svg_file.data);
            let text = await res.text();
            await suryadb().get_svg_map.put({ id: 1, data: text });
        } catch (err) {
            console.log(err);
        }
    }
}

export async function insert_pier_legend_details() {
    let pier_legend_details = await axios.get(`/projects/pierslenged`);
    let pireLegendData = pier_legend_details.data
    await suryadb().get_pier_legend.put({ id: 1, data: pireLegendData });
}

export async function insert_svg_block_progress_activity_wise() {

    let svgBlockProgress = await axios.get(`/progress/get_progress_block_wise_all`)
    svgBlockProgress = svgBlockProgress?.data
    if (svgBlockProgress) {
        Object.entries(svgBlockProgress).forEach(async ([key, value]) => {
            await suryadb().get_svg_block_progress_activitywise.put({
              id: parseInt(key),
              data: value,
            });
          });
    }
}

export async function insert_zimmerman_bulk_piers() {
    let is_zimmeran = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
    if (!localStorage.getItem("get-data-initially")) {
        let data = await axios.get(`/tracker_row/bulk/zimmerman?block=all`)
        data = data?.data
        for (let i in data) {
            await suryadb().get_zimmerman_bulk_piers.put({ id: parseInt(i), data: data[i] });
        }
    }

}
export async function insert_bulk_piers_torquetubes() {
    if (!localStorage.getItem("get-data-initially")) {
        let data = await axios.get(`/tracker_row/bulk/all?block=all`)
        data = data?.data
        for (let i in data) {
            await suryadb().get_bulk_piers_torquetubes.put({ id: parseInt(i), data: data[i] });
        }
    }

}
export async function insert_d3_xscale_yscale_blockwise(blockids) {
    if (!localStorage.getItem("get-data-initially")) {
        let d3XscaleYscaleUrls = []
        for (let i of blockids) {
            d3XscaleYscaleUrls.push({ url: `/projects/pier-tt/x-y-min-max?block_id=${i?.id}`, blockId: i?.id });
        }
        let d3XscaleYscaleResponse = await Promise.all(d3XscaleYscaleUrls.map((endpoint) => axios.get(endpoint.url)))
        for (let j in d3XscaleYscaleResponse) {
            let blockId = d3XscaleYscaleUrls[j].blockId
            await suryadb().d3_xscale_yscale_blockwise.put({ id: blockId, data: d3XscaleYscaleResponse[j].data });
        }
    }

}
export async function insert_zimmerman_table_coordinates(blockids) {
    let zimmermanTableCoordinatesUrl = []
    for (let i of blockids) {
        zimmermanTableCoordinatesUrl.push({ url: `/projects/get_all_tracker_coordinates?block_id=${i?.id}`, blockId: i?.id });
    }
    let zimmermanTableCoordinatesResponse = await Promise.all(zimmermanTableCoordinatesUrl.map((endpoint) => axios.get(endpoint.url)))
    for (let j in zimmermanTableCoordinatesResponse) {
        let blockId = zimmermanTableCoordinatesUrl[j].blockId
        await suryadb().zimmerman_table_coordinates.put({ id: blockId, data: zimmermanTableCoordinatesResponse[j].data });
    }
}


export async function get_zimmerman_table_coordinates_blockwise(blockId) {
    if (!isNaN(blockId)) {
        let data = await suryadb().zimmerman_table_coordinates.get(blockId)
        if (data !== undefined) {
            return data?.data
        }
    }
}
export async function get_bulk_piers_torquetubes(blockId) {
    if (!isNaN(blockId)) {
        let data = await suryadb().get_bulk_piers_torquetubes.get(blockId)
        if (data !== undefined) {
            return data?.data
        }
    }

}

export async function get_zimmerman_bulk_piers(blockId) {
    if (!isNaN(blockId)) {
        let data = await suryadb().get_zimmerman_bulk_piers.get(blockId)
        if (data !== undefined) {
            return data?.data
        }
    }

}

export async function get_blocks() {
    let data = []
    data = await suryadb().get_blocks.toArray();
    if (data) {
        return data
    }
}

export async function get_pier_legend_details() {
    let data = []
    data = await suryadb().get_pier_legend.toArray();
    if (data) {
        return data[0].data
    }
}


export async function get_svg_map_data() {
    let data = []
    data = await suryadb().get_svg_map.toArray();
    if (data) {
        return data[0].data
    }
}
export async function get_d3_xscale_yscale_blockwise(blockid) {

    let data = await suryadb().d3_xscale_yscale_blockwise.get(blockid)

    return data?.data
}