import {
  Box,
  Grid,
  Typography,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  Select,
  MenuItem,
  TextField,
  Modal,
  Button,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import Header from "../../../components/appBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAtom } from "jotai";
import constructionProgressTrackerrowWiseAtom, {
  fetchConstructionProgressTrackerrowWiseAtom,
} from "../../../data/constructionProgressAtom";
import activitiesAtom, { fetchActivitiesAtom } from "../../../data/activitiesAtom";
import selectedRowsAtom, { updateSelectedRowsAtom } from "../../../data/selectedRowsAtom";
import lodash from "lodash";
import dashboardAtom, {
  fetchTrackerTypesForProjectAtom,
  fetchChecklistActivityAtom,
  fetchExistingDistrbutedInstalledAtom,
  updateExistingDistrbutedInstalledAtom,
  fetchDistributedInstalledBomAtom,
  fetchTrackerTypeNamesAtom,
  fetchBulkPiersTorqueTubesBlockDataAtom,
  fetchSelectedTrackerTypeIdsAtom,
  fetchPreviousSelectedTrackerTypeIdAtom,
  fetchHighLightSelectedTrackerTypeIdsAtom,
  fetchZimmermanBulkPiersAtom,
  fetchZimmermanTableWisePierPartBomAtom,
  updateDistributedAndInstalledQuantityAtom,
  updateInprogressDeployedCountAtom,
  getListOfPiersTorqueTubeIdsWithStatusAtom,
  getSelectedPiersTorqueTubesDataAtom,
} from "../../../data/dashboardAtom";
import { insertCPQueueAtom } from "../../../data/cpQueueAtom";
import produce from "immer";
import syncStatusAtom from "../../../data/syncStatusAtom";
import ConstructionInprogressTable from "../../../components/constructionInprogressTable";
import { get_distributed_and_installed_bom_for_activity, get_updated_bom_for_distributed_installed_for_selected_activity_trackerow } from "../../../components/functions/constructionProgress";
import UndoIcon from "@mui/icons-material/Undo";
import ConstructionProgressPierTTSelection from "../../../components/constructionProgressPierTTSelection";
import { IS_PROJECT_TYPE_ZIMMERMAN_FLAG } from "../../../featureFlags/featureFlags";
import partsAtom, { fetchPartsAtom } from "../../../data/partsAtom";

const InprogressDistributedRows = [
  {
    "part_number": "Part Number",
    "part_description": "Part Description",
    "total_quantity": "Total Qty",
    "type": "Distributed Quantities"
  }
]
const InProgressInstalledRows = [
  {
    "part_number": "Part Number",
    "part_description": "Part Description",
    "total_quantity": "Total Qty",
    "type": "Installed Quantities"
  }
]

const Msg = () => (
  <Box sx={{ display: "flex" }}>
    <Box
      sx={{
        padding: "0px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingRight: "40px",
      }}
    >
      <Typography variant="h6"> Applied Successfully</Typography>
      <Typography>To all the selected trackers</Typography>
    </Box>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: " 15px ",
        color: "#F16253",
      }}
    >
      <UndoIcon sx={{ fontSize: "25px" }} />
      <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>UNDO</Typography>
    </Box>
  </Box>
);

const ConstructionProgressChecksheet = () => {
  const [selectedRows] = useAtom(selectedRowsAtom);
  const [constructionProgressTrackerrowWise, setConstructionProgressTrackerrowWise] = useAtom(constructionProgressTrackerrowWiseAtom);
  const [activities] = useAtom(activitiesAtom);
  const [dashboard, setDashboard] = useAtom(dashboardAtom);
  const [, updateDistributedAndInstalledQuantity] = useAtom(updateDistributedAndInstalledQuantityAtom)
  const [, updateInprogressDeployedCount] = useAtom(updateInprogressDeployedCountAtom)
  const [, getSelectedPiersTorqueTubesData] = useAtom(getSelectedPiersTorqueTubesDataAtom)
  const [, getListOfPiersTorqueTubeIdsWithStatus] = useAtom(getListOfPiersTorqueTubeIdsWithStatusAtom)
  const [, fetchChecklistActivity] = useAtom(fetchChecklistActivityAtom);
  const [foundType, setFoundType] = useState("");
  const [updateProgressQty, setUpdateProgressQty] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openPierTorqueTubeModal, setOpenPierTorqueTubeModal] = useState(false)
  const [status, setStatus] = useState("");

  const [activityId, setActivityId] = useState();
  const [btnenable, setbtnenable] = useState(false);
  const [listParts] = useAtom(partsAtom);
  const [, fetchParts] = useAtom(fetchPartsAtom);
  const [, fetchConstructionProgressTrackerrowWise] = useAtom(fetchConstructionProgressTrackerrowWiseAtom);
  const [, fetchBulkPiersTorqueTubesBlockData] = useAtom(fetchBulkPiersTorqueTubesBlockDataAtom)
  const [, fetchoverallTt] = useAtom(fetchTrackerTypesForProjectAtom);
  const [, insertCPQueue] = useAtom(insertCPQueueAtom);
  const [, fetchActivities] = useAtom(fetchActivitiesAtom);
  const [, fetchExistingDistrbutedInstalled] = useAtom(fetchExistingDistrbutedInstalledAtom);
  const [, updateExistingDistrbutedInstalled] = useAtom(updateExistingDistrbutedInstalledAtom);
  const [, fetchDistributedInstalledBom] = useAtom(fetchDistributedInstalledBomAtom)
  const [syncStatusData] = useAtom(syncStatusAtom);
  const [bomDetails, setBomDetails] = useState([]);
  const [blockDetails, setBlockDetails] = useState({})
  const [inprogressDeployedPartCount, setInprogressDeployedPartCount] = useState(0)
  const [selectedType, setSelectedType] = useState(1)
  const params = useParams();
  const [, fetchTrackerTypeNames] = useAtom(fetchTrackerTypeNamesAtom)
  const [piersTorqueTubesDetails, setPiersTorqueTubeDetails] = useState({})
  const [, fetchSelectedTrackerTypeIds] = useAtom(fetchSelectedTrackerTypeIdsAtom)
  const [, fetchPreviousSelectedTrackerTypeId] = useAtom(fetchPreviousSelectedTrackerTypeIdAtom)
  const [, fetchHighLightSelectedTrackerTypeIds] = useAtom(fetchHighLightSelectedTrackerTypeIdsAtom)
  const [, updateSelectedRows] = useAtom(updateSelectedRowsAtom)
  const [trackerTypeDetails, setTrackerTypeDetails] = useState()
  const [, fetchZimmermanBulkPiers] = useAtom(fetchZimmermanBulkPiersAtom)
  const [isZimmerman, setIsZimmerman] = useState(false)
  const [zimmermanPierData, setZimmermanPierData] = useState({})
  const [, fetchZimmermanTableWisePierPartBom] = useAtom(fetchZimmermanTableWisePierPartBomAtom)
  const [clearInprogressTableData, setClearInprogressTableData] = useState(false)
  const [completedInprogressPiersTTData, setCompletedInprogressPiersTTData] = useState({})
  const navigate = useNavigate();


  const handleClose = () => {
    setOpenModal(false)
    setInprogressDeployedPartCount(0)
    setClearInprogressTableData(!clearInprogressTableData)
  };


  const handleClosePierTorqueTubeModal = () => setOpenPierTorqueTubeModal(false)
  useEffect(() => {
    async function fetchProjectTypeFlag() {
      let is_zimmeran = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
      setIsZimmerman(is_zimmeran)
    }
    fetchProjectTypeFlag();
  }, [])

  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    init();
  }, [syncStatusData.syncing]);

  const init = async () => {
    try {
      await fetchActivities();
      await fetchConstructionProgressTrackerrowWise(parseInt(params?.id));
      await fetchBulkPiersTorqueTubesBlockData(parseInt(params.id))
      await fetchoverallTt();
      await fetchChecklistActivity();
      await fetchDistributedInstalledBom()
      await fetchExistingDistrbutedInstalled()
      await fetchTrackerTypeNames()
      await fetchZimmermanBulkPiers(parseInt(params.id))
      await fetchZimmermanTableWisePierPartBom()
      await fetchParts()
    } catch (err) {
      console.log(err);
    }
  };


  const getLinkedPartName = () => {
    let partName = ""
    if (activities?.construction && activityId !== "All" && activities?.construction[activityId]) {
      for (let i of listParts) {
        if (i.id === activities?.construction[activityId]?.part_id) {
          partName = i?.data?.part_name
        }
      }
    }
    return partName
  }

  const handleClickNext = () => {
    setOpenModal(false)
    setSelectedType(1)
    setOpenPierTorqueTubeModal(true)
  }

  useEffect(() => {
    if (isZimmerman === true && parseInt(status) === 1) {
      if (dashboard?.zimmermanBulkPiers && dashboard?.zimmermanBulkPiers.length !== 0) {
        let blockPierData = dashboard?.zimmermanBulkPiers
        if (blockPierData?.length !== 0) {
          setZimmermanPierData(blockPierData)
        }
      }
    }
  }, [selectedRows, status])



  useEffect(() => {
    if (isZimmerman === true) {
      if (dashboard?.zimmermanBulkPiers && dashboard?.zimmermanBulkPiers.length !== 0) {
        let blockPierData = dashboard?.zimmermanBulkPiers
        if (blockPierData?.length !== 0) {
          setBlockDetails(blockPierData[0])
        }
      }
    } else {
      if (dashboard?.bulkPiersTorqueTubesBlockData && dashboard?.bulkPiersTorqueTubesBlockData?.piers) {
        let blockPierData = dashboard?.bulkPiersTorqueTubesBlockData?.piers
        let trackerRowId = parseInt(params.trackerrowid)
        if (blockPierData[trackerRowId] !== undefined && blockPierData[trackerRowId].length !== 0) {
          setBlockDetails(blockPierData[trackerRowId][0])
        }
      }
    }
    if (dashboard?.trackerTypeNames) {
      let trackerTypesDetails = dashboard?.trackerTypeNames.find(
        (j) => j?.id === parseInt(params.trackertypeid)
      );
      setTrackerTypeDetails(trackerTypesDetails)
    }

  }, [isZimmerman])

  useEffect(() => {
    if (parseInt(status) === 1 && dashboard?.bulkPiersTorqueTubesBlockData && selectedRows?.length === 1) {
      let pierTTObj = {}
      let trackerrowId = parseInt(params.trackerrowid)
      let piersTorqueTubeData = dashboard?.bulkPiersTorqueTubesBlockData
      if (piersTorqueTubeData["piers"][trackerrowId] && piersTorqueTubeData["tt"][trackerrowId]) {
        pierTTObj["piers"] = piersTorqueTubeData["piers"][trackerrowId]
        pierTTObj["torque_tube"] = piersTorqueTubeData["tt"][trackerrowId]
        setPiersTorqueTubeDetails(pierTTObj)
      }
    }

  }, [status])

  const getTotalPartCountForSelectedTrackerrowAndActivity = () => {
    let totalPartCount = 0
    if (selectedRows.length === 1 && parseInt(status) === 1 && activityId) {
      let tracker_type_id = parseInt(params.trackertypeid)
      let partId_trackertypeId_key = `${activities?.construction[activityId]?.part_id}_${tracker_type_id}`
      if (dashboard?.part_per_tracker[partId_trackertypeId_key]) {
        totalPartCount = dashboard?.part_per_tracker[partId_trackertypeId_key]
      }
    }
    return totalPartCount
  }


  const getBomDetails = async () => {
    let bomData = await get_distributed_and_installed_bom_for_activity(activityId, selectedRows[0], dashboard?.distributedInstalledBom, dashboard?.total_installed_distributed, dashboard?.existing_bom_per_tracker_row, isZimmerman, dashboard?.zimmermanTableWisePierPartBom)
    if (bomData) {
      if (Object.keys(bomData).length === 1) {
        for (let i in bomData) {
          setFoundType(i)
          setBomDetails(bomData)
        }
      } else {
        setFoundType("distributed_installed")
        setBomDetails(bomData)
      }
    }
  }
  useEffect(() => {
    if (dashboard?.distributedInstalledBom && activityId) {
      getBomDetails()
    }

  }, [status])

  const checkSubActivity = (data) => {
    let temp = false;
    Object.keys(activities.construction).map((key) => {
      if (
        activities.construction[key].parent_activity_id === parseInt(data)
      ) {
        temp = true;
      }
    });
    return temp;
  };

  const handleStatusChange = async (activity_id, status) => {
    let checkActivityType = "";
    let parentActivityId;
    let subActivitiesListWithStatus = {};
    let cpdata = [];
    if (activities.construction[activity_id].parent_activity_id === null) {
      checkActivityType = "parent_activity";
    } else {
      checkActivityType = "sub_activity";
    }
    if (checkActivityType === "parent_activity") {
      let temp = await handleChange(activity_id, status);
      cpdata.push(temp)
      for (let i in activities.construction) {
        if (activities.construction[i].parent_activity_id === activity_id) {
          if (status === 0 || status === 2) {
            let temp = await handleChange(parseInt(i), status);
            cpdata.push(temp)
          }
        }
      }
    } else {
      parentActivityId = activities.construction[activity_id].parent_activity_id;
      let selectedActivityData = await handleChange(activity_id, status);
      cpdata.push(selectedActivityData)
      let parentActivityData = await handleChange(activity_id, status, checkActivityType, parentActivityId);
      cpdata.push(parentActivityData)

      // for (let i in activities.construction) {
      //   if (activities.construction[i].parent_activity_id === parentActivityId) {
      //     subActivitiesListWithStatus[parseInt(i)] = parseInt(i) === activity_id ? status : statusActivityForTrackerRowID(parseInt(i))
      //   }
      // }

      // const allCompleted = Object.values(subActivitiesListWithStatus).every(value => value === 2);

      // if (allCompleted) {
      //   let temp = await handleChange(parentActivityId, 2);
      //   cpdata.push(temp)
      // } else {
      //   let temp = await handleChange(parentActivityId, 0);
      //   cpdata.push(temp)
      // }
      // let temp = await handleChange(activity_id, status);
      // cpdata.push(temp)


    }
    if (parseInt(status) !== 1) {
      let copyOfTrackerRowWise = lodash.cloneDeep(constructionProgressTrackerrowWise);

      for (let j of cpdata) {
        let trackerActivityKey = `${parseInt(j?.tracker_row_id)}_${j?.activity_id}`;

        if (constructionProgressTrackerrowWise[trackerActivityKey]) {
          copyOfTrackerRowWise[trackerActivityKey].status = j?.status;
          copyOfTrackerRowWise[trackerActivityKey].completion_percentage = j?.completion_percentage;
          copyOfTrackerRowWise[trackerActivityKey].deployed_part_count = j?.deployed_part_count;
          copyOfTrackerRowWise[trackerActivityKey].total_part_count = j?.total_part_count;
        } else {
          copyOfTrackerRowWise[trackerActivityKey] = {
            activity_id: j?.activity_id,
            completion_percentage: j?.completion_percentage,
            deployed_part_count: j?.deployed_part_count,
            status: j?.status,
            total_part_count: j?.total_part_count,
            selectedRows: j?.tracker_row_id,
            piers: [],
            torque_tubes: []
          }
        }
      }
      let cpObj = {}
      let blockId = params?.id
      cpObj[blockId] = cpdata
      await insertCPQueue(cpObj);
      setConstructionProgressTrackerrowWise(copyOfTrackerRowWise)
    }
  };

  useEffect(() => {
    if (Object.keys(dashboard?.listOfPiersTorqueTubeIdsWithStatus).length > 0) {
      let trackerRowId = selectedRows[0]?.split("-")[1]
      let trackerTypeId = selectedRows[0]?.split("-")[2]
      let completedPiersTorqueTubes = { [trackerRowId]: dashboard?.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId] }
      setCompletedInprogressPiersTTData(completedPiersTorqueTubes)
    }

  }, [dashboard?.listOfPiersTorqueTubeIdsWithStatus])

  const handleChange = async (activity_id, status, checkActivityType, parentActivityId) => {
    setActivityId(activity_id);
    setStatus(status);
    if (parseInt(status) !== 1) {
      let bomData = await get_distributed_and_installed_bom_for_activity(activity_id, selectedRows[0], dashboard?.distributedInstalledBom, dashboard?.total_installed_distributed, dashboard?.existing_bom_per_tracker_row, isZimmerman, dashboard?.zimmermanTableWisePierPartBom)
      let updatedData = await get_updated_bom_for_distributed_installed_for_selected_activity_trackerow(dashboard?.bulkPiersTorqueTubesBlockData, blockDetails, bomData, activity_id, updateProgressQty, status, foundType, dashboard?.part_per_tracker, activities?.construction, selectedRows[0], inprogressDeployedPartCount, dashboard?.listOfPiersTorqueTubeIdsWithStatus, isZimmerman, checkActivityType, parentActivityId, constructionProgressTrackerrowWise)
      return updatedData
    }

  };

  const getDeployedPartCountOfSelectedTrackerrow = () => {

    let deployedCount = 0
    if (selectedRows.length === 1 && parseInt(status) === 1 && localStorage.getItem("construction-act-id") !== "All") {
      let trackerrowId_activityId_key = `${params.trackerrowid}_${JSON.parse(localStorage.getItem("construction-act-id"))}`
      if (constructionProgressTrackerrowWise[trackerrowId_activityId_key]) {
        deployedCount = constructionProgressTrackerrowWise[trackerrowId_activityId_key].deployed_part_count
      }
    }
    return deployedCount
  }

  const handleClick = async () => {
    try {
      let cpdata = [];
      let subActivityData
      let parentActivityData
      let checkActivityType = "";
      if (activities.construction[activityId].parent_activity_id === null) {
        checkActivityType = "parent_activity";
      } else {
        checkActivityType = "sub_activity";
      }
      let parentActivityId = activities.construction[activityId].parent_activity_id;

      if (isZimmerman === true) {
        subActivityData = await get_updated_bom_for_distributed_installed_for_selected_activity_trackerow(dashboard?.zimmermanBulkPiers, blockDetails, bomDetails, activityId, dashboard?.updateDistributedAndInstalledQuantity, status, foundType, dashboard?.part_per_tracker, activities?.construction, selectedRows[0], inprogressDeployedPartCount, dashboard?.listOfZimmermanPierIdsWithStatus, isZimmerman)
        cpdata.push(subActivityData)
        parentActivityData = await get_updated_bom_for_distributed_installed_for_selected_activity_trackerow(dashboard?.zimmermanBulkPiers, blockDetails, bomDetails, activityId, dashboard?.updateDistributedAndInstalledQuantity, status, foundType, dashboard?.part_per_tracker, activities?.construction, selectedRows[0], inprogressDeployedPartCount, dashboard?.listOfZimmermanPierIdsWithStatus, isZimmerman, checkActivityType, parentActivityId, constructionProgressTrackerrowWise)
        cpdata.push(parentActivityData)
      } else {
        subActivityData = await get_updated_bom_for_distributed_installed_for_selected_activity_trackerow(dashboard?.bulkPiersTorqueTubesBlockData, blockDetails, bomDetails, activityId, dashboard?.updateDistributedAndInstalledQuantity, status, foundType, dashboard?.part_per_tracker, activities?.construction, selectedRows[0], inprogressDeployedPartCount, completedInprogressPiersTTData, isZimmerman)
        cpdata.push(subActivityData)
        parentActivityData = await get_updated_bom_for_distributed_installed_for_selected_activity_trackerow(dashboard?.bulkPiersTorqueTubesBlockData, blockDetails, bomDetails, activityId, dashboard?.updateDistributedAndInstalledQuantity, status, foundType, dashboard?.part_per_tracker, activities?.construction, selectedRows[0], inprogressDeployedPartCount, completedInprogressPiersTTData, isZimmerman, checkActivityType, parentActivityId, constructionProgressTrackerrowWise)
        cpdata.push(parentActivityData)
      }
      let copyOfTrackerRowWise = lodash.cloneDeep(constructionProgressTrackerrowWise);
      for (let j of cpdata) {
        let trackerActivityKey = `${parseInt(j?.tracker_row_id)}_${j?.activity_id}`;

        if (constructionProgressTrackerrowWise[trackerActivityKey]) {
          copyOfTrackerRowWise[trackerActivityKey].status = status;
          copyOfTrackerRowWise[trackerActivityKey].completion_percentage = j?.completion_percentage;
          copyOfTrackerRowWise[trackerActivityKey].deployed_part_count = j?.deployed_part_count;
          copyOfTrackerRowWise[trackerActivityKey].total_part_count = j?.total_part_count;
          if (parseInt(j.status) === 1) {
            copyOfTrackerRowWise[trackerActivityKey].piers = j?.piers
            copyOfTrackerRowWise[trackerActivityKey].torque_tubes = j?.torque_tubes
          }
        } else {
          copyOfTrackerRowWise[trackerActivityKey] = {
            activity_id: j?.activity_id,
            completion_percentage: j?.completion_percentage,
            deployed_part_count: j?.deployed_part_count,
            status: j?.status,
            total_part_count: j?.total_part_count,
            selectedRows: j?.tracker_row_id,
            piers: j?.piers ? j?.piers : [],
            torque_tubes: j?.torque_tubes ? j?.torque_tubes : []
          }
        }
      }

      for (let i in dashboard?.updateDistributedAndInstalledQuantity) {
        if (!dashboard?.existing_bom_per_tracker_row[i]) {
          setDashboard(
            produce((draft) => {
              draft.existing_bom_per_tracker_row[i] = {
                distributed: dashboard?.updateDistributedAndInstalledQuantity[i].Distributed
                  ? dashboard?.updateDistributedAndInstalledQuantity[i].Distributed
                  : null,
                installed: dashboard?.updateDistributedAndInstalledQuantity[i].Installed
                  ? dashboard?.updateDistributedAndInstalledQuantity[i].Installed
                  : null,
              };
            })
          );
        }
        if (dashboard?.updateDistributedAndInstalledQuantity[i]["installed"]) {
          setDashboard(
            produce((draft) => {
              draft.existing_bom_per_tracker_row[i]["installed"] =
                dashboard?.updateDistributedAndInstalledQuantity[i]["installed"];
            })
          );
        }
        if (dashboard?.updateDistributedAndInstalledQuantity[i]["distributed"]) {
          setDashboard(
            produce((draft) => {
              draft.existing_bom_per_tracker_row[i]["distributed"] =
                dashboard?.updateDistributedAndInstalledQuantity[i]["distributed"];
            })
          );
        }
      }
      let cpObj = {}
      let blockId = params?.id
      cpObj[blockId] = cpdata
      await insertCPQueue(cpObj);
      updateExistingDistrbutedInstalled(dashboard?.updateDistributedAndInstalledQuantity);
      updateInprogressDeployedCount({})
      setInprogressDeployedPartCount({})
      getSelectedPiersTorqueTubesData({})
      getListOfPiersTorqueTubeIdsWithStatus({})
      setConstructionProgressTrackerrowWise(copyOfTrackerRowWise)
      setOpenPierTorqueTubeModal(false);
      setbtnenable(false);
      toast.success(<Msg />, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

    } catch (err) {
      console.log(err);
    }
  };

  const getInProgressExistingLinkedPart = async () => {
    let trackerTypeId = selectedRows[0]?.split("-")[2]
    let existingLinkedPartCount = getDeployedPartCountOfSelectedTrackerrow()
    if (existingLinkedPartCount > 0 || (Object.keys(inprogressDeployedPartCount).length !== 0 && inprogressDeployedPartCount[trackerTypeId] !== 0)) {
      setbtnenable(true)
    }
    else {
      setbtnenable(false)
    }
  }

  // useEffect(() => {
  //   if (parseInt(status) === 1) {
  //     let trackerTypeId = selectedRows[0]?.split("-")[2]
  //     let existingLinkedPartCount = getDeployedPartCountOfSelectedTrackerrow()
  //     if (existingLinkedPartCount !== 0 || (inprogressDeployedPartCount[trackerTypeId] !== 0 && inprogressDeployedPartCount[trackerTypeId] !== "")) {
  //       setbtnenable(true)
  //       if (inprogressDeployedPartCount[trackerTypeId] === 0) {
  //         setInprogressDeployedPartCount({ [trackerTypeId]: existingLinkedPartCount })
  //       }
  //     }
  //     else {
  //       setbtnenable(false)
  //     }
  //   }
  // }, [status, openModal])

  useEffect(() => {
    if (parseInt(status) === 1 && selectedRows.length === 1) {
      getInProgressExistingLinkedPart()
    }

  }, [status, openModal])

  useEffect(() => {
    let trackerTypeId = selectedRows[0]?.split("-")[2]
    if (Object.keys(inprogressDeployedPartCount).length > 0 && !isNaN(inprogressDeployedPartCount[trackerTypeId]) && inprogressDeployedPartCount[trackerTypeId] !== 0 && inprogressDeployedPartCount[trackerTypeId] !== "") {
      setbtnenable(true)
    } else {
      setbtnenable(false)
    }
  }, [inprogressDeployedPartCount])

  const statusActivityForTrackerRowID = (activityID) => {
    if (
      constructionProgressTrackerrowWise[
      `${parseInt(params.trackerrowid)}_${activityID}`
      ] === undefined
    ) {
      return 0;
    } else {
      if (
        constructionProgressTrackerrowWise[
        `${parseInt(params.trackerrowid)}_${activityID}`
        ]
      ) {
        let i =
          constructionProgressTrackerrowWise[
          `${parseInt(params.trackerrowid)}_${activityID}`
          ];
        if (i?.activity_id === activityID) {
          return parseInt(i?.status);
        }
      }
    }
    return 0;
  };

  let parActivityIds = [];
  let finalarr = [];

  if (activities?.construction) {
    Object.keys(activities.construction)?.map((i) => {
      if (!activities.construction[i]?.parent_activity_id) {
        parActivityIds.push(i);
      }
    });
  }
  parActivityIds.map((j) => {
    finalarr.push(parseInt(j));
    Object.values(activities.construction).map((y) => {
      if (
        activities.construction[y?.id]?.parent_activity_id === parseInt(j)
      ) {
        finalarr.push(y?.id);
      }
    });
  });

  let notesArr = [];

  dashboard?.getChecklistActivity?.map((i) => {
    if (i?.id === parseInt(params.trackertypeid)) {
      notesArr.push(i?.data);
    }
  });
  const showNotes = (data) => {
    let notes = "";
    notesArr[0]?.map((i) => {
      if (i?.activity_id === data) {
        notes = i?.notes;
      } else {
        i?.sub_activity?.map((j) => {
          if (j?.activity_id === data) {
            notes = j?.notes;
          }
        });
      }
    });
    return notes;
  };

  const handleInprogressDeployedPartCount = (e) => {
    let trackerTypeId = selectedRows[0]?.split("-")[2]
    let maxVal = getTotalPartCountForSelectedTrackerrowAndActivity()
    if (parseInt(e.target.value) > maxVal) {
      setbtnenable(false)
      toast.error("Please enter the value lesser than Total Part Count");
    } else {
      setbtnenable(true)
      setInprogressDeployedPartCount({ [trackerTypeId]: parseInt(e.target.value) })
    }
  }
  const handleBackButton = async () => {
    try {
      await fetchPreviousSelectedTrackerTypeId(null)
      await fetchSelectedTrackerTypeIds([])
      await fetchHighLightSelectedTrackerTypeIds([])
      await updateSelectedRows([])
      navigate(`/home/gpslocation/${params.id}/constructionprogress`)
    } catch (err) {
      console.log(err)
    }
  }
  const getInprogresDeployedPartCountDefaultValue = () => {
    let trackerTypeId = selectedRows[0]?.split("-")[2]
    let deployedCount = 0
    if (inprogressDeployedPartCount[trackerTypeId] !== 0) {
      deployedCount = inprogressDeployedPartCount[trackerTypeId]
    } else {
      deployedCount =  getDeployedPartCountOfSelectedTrackerrow()
    }
    return deployedCount
  }

  return (
    <Box>
      <Header />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "64px",
          backgroundColor: "#262223",
          color: "white",
        }}
      >
        <Box sx={{ paddingX: "10px" }}>
          <ArrowBackIcon
            // sx={{ padding: " 0px 18px" }}
            onClick={handleBackButton}

          />
        </Box>
        <Typography sx={{ fontWeight: "600", fontSize: "20px", }}>
          Construction Progress Checksheet
        </Typography>
      </Box>
      <Box>
        <Grid container>
          <Grid item
            xs={12}
          >
            <Box sx={{ padding: "10px 20px" }}>
              {isZimmerman === true ?
                <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                  {blockDetails?.block}, Row {selectedRows[0]?.split("-")[4]}, Table
                  {selectedRows[0]?.split("-")[3]}
                </Typography> :
                <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                  {blockDetails?.block}, Section {blockDetails?.section}, Row{" "}
                  {blockDetails?.row}
                </Typography>}
              <Typography sx={{ color: "#696F79", fontSize: "14px" }}>
                {trackerTypeDetails?.tracker_type}
              </Typography>
            </Box>
            <Divider />
            <TableContainer sx={{ height: "600px", overflowY: "auto" }}>
              <Table sx={{ padding: "10px" }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{
                      paddingY: "10px",
                      // width: "450px"
                      width: "500px",
                      paddingLeft: "20px"
                    }}>
                      Activity/Sub-activity
                    </TableCell>
                    <TableCell sx={{
                      paddingY: "10px",
                      width: "250px"
                    }}
                    >% Weight</TableCell>
                    <TableCell sx={{
                      paddingY: "10px",
                      paddingLeft: "30px",
                      width: "200px"
                    }}>
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {finalarr?.map((i) => {

                    return (
                      <TableRow>
                        <TableCell sx={{ border: "none", paddingLeft: "15px" }}>
                          {activities.construction[i].parent_activity_id ? (
                            <p style={{ marginTop: "5px" }}>
                              <i
                                style={{
                                  width: "80px",
                                  border: "solid gray",
                                  borderWidth: "0 0px 2px 0",
                                  transform: "rotate(90deg)",
                                  display: "inline-block",
                                  margin: "-6px 14px 24px 0px",
                                }}
                              ></i>
                              <i
                                style={{
                                  width: "12px",
                                  border: "solid gray",
                                  borderWidth: "0 0px 2px 0",
                                  display: "inline-block",
                                  margin: "0px 0 5px -55px",
                                }}
                              ></i>
                              {activities.construction[i]?.name} <br />
                              <Typography
                                sx={{
                                  paddingLeft: "50px",
                                  textDecoration: "underline",
                                }}
                              >
                                {showNotes(i)}
                              </Typography>
                            </p>
                          ) : (
                            <Typography
                              sx={{
                                paddingLeft: "5px",
                                paddingBottom: "25px",
                                paddingTop: "20px",
                              }}
                            >
                              {activities.construction[i]?.name}
                              <Typography
                                sx={{
                                  textDecoration: "underline",
                                }}
                              >
                                {showNotes(i)}
                              </Typography>
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            paddingBottom:
                              activities.construction[i]
                                .parent_activity_id === null
                                ? "14px"
                                : "10px",
                          }}
                        >
                          {activities.construction[i]?.weight}%
                        </TableCell>
                        <TableCell sx={{ border: "none" }}>
                          <Select
                            key={i}
                            value={statusActivityForTrackerRowID(i)}
                            sx={{
                              paddingLeft: "0px",
                              width: "150px",
                              height: "45px",
                              boxShadow: "none",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: 0,
                              },
                            }}
                            onChange={(e) => {
                              handleStatusChange(i, e.target.value);
                              e.target.value === 1
                                ? setOpenModal(true)
                                : setOpenModal(false);
                            }}
                          >
                            <MenuItem value={0}>Not Started</MenuItem>
                            {/*                            
                            {process.env.REACT_APP_INPROGRESS === "true" ? activities.construction[i].parent_activity_id ===
                              null && checkSubActivity(i) ? null : (
                              <MenuItem value={1}>In Progress</MenuItem>
                            ) : null} */}

                            {statusActivityForTrackerRowID(i) === 1 && activities.construction[i].parent_activity_id === null || checkSubActivity(i) === false ?
                              <MenuItem value={1}>In Progress</MenuItem> : null}

                            <MenuItem value={2}>Completed</MenuItem>
                          </Select>
                        </TableCell>

                      </TableRow>
                    );
                  })}

                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
      {/* modal */}

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "97vh",
            width: "97vw",
            backgroundColor: "#FFFFFF",
            color: "black",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "10px",
              margin: "5px"
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {isZimmerman === true ?
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {blockDetails?.block}, Row {selectedRows[0]?.split("-")[4]}, Table
                  {selectedRows[0]?.split("-")[3]}
                </Typography> :
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {blockDetails?.block}, Section {blockDetails?.section}, Row{" "}
                  {blockDetails?.row}
                </Typography>}
              <Typography sx={{ color: "#696F79", fontSize: "16px", paddingY: "3px" }}>
                {trackerTypeDetails?.tracker_type}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <CloseIcon
                sx={{ padding: "10px 0  10px 15px" }}
                onClick={handleClose}
              />
            </Box>
          </Box>
          <Divider />

          <Box sx={{ display: "flex", flexDirection: "column", marginX: "5px" }}>

            {Object.keys(bomDetails).length > 0 ?
              <Box>
                <Typography sx={{ fontWeight: 600, fontSize: "18px", paddingX: "10px", paddingY: "5px" }}>Enter distributed and installed counts below for BOM tracking</Typography>
                {foundType === "distributed" ?
                  < ConstructionInprogressTable rows={InprogressDistributedRows} foundType={foundType} bomDetails={bomDetails["distributed"]} trackerrowId={params.trackerrowid} isZimmerman={isZimmerman} clearTableData={clearInprogressTableData} /> : null}
                {foundType === "installed" ?
                  < ConstructionInprogressTable rows={InProgressInstalledRows} foundType={foundType} bomDetails={bomDetails["installed"]} trackerrowId={params.trackerrowid} isZimmerman={isZimmerman} clearTableData={clearInprogressTableData} /> : null}

                {foundType === "distributed_installed" ?
                  <Box >
                    <Box sx={{ marginY: "10px" }}>
                      < ConstructionInprogressTable rows={InprogressDistributedRows} foundType={foundType} bomDetails={bomDetails["distributed"]} trackerrowId={params.trackerrowid} isZimmerman={isZimmerman} clearTableData={clearInprogressTableData} />
                    </Box>
                    <Box sx={{ marginY: "10px" }}>
                      < ConstructionInprogressTable rows={InProgressInstalledRows} foundType={foundType} bomDetails={bomDetails["installed"]} trackerrowId={params.trackerrowid} isZimmerman={isZimmerman} clearTableData={clearInprogressTableData} />
                    </Box>
                  </Box>
                  : null}
              </Box> : null}
            <Box sx={{ display: "flex", flexDirection: "column", marginX: "5px", marginTop: "10px" }}>
              <Typography sx={{ fontWeight: 600, fontSize: "18px", paddingX: "5px", }}>Enter linked part counts for progress calculation</Typography>
              <Box sx={{ margin: "5px", display: "flex", alignItems: "center", justifyContent: "start", }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", }}>
                  <Typography sx={{ fontSize: "14px", paddingRight: "5px" }}>Linked Part <br />{getLinkedPartName()}</Typography>
                  <TextField
                    name="deployed part count"
                    defaultValue={getInprogresDeployedPartCountDefaultValue()}
                    onChange={(e) => handleInprogressDeployedPartCount(e)}
                    sx={{
                      // ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      width: "150px",
                      paddingLeft: "10px"
                    }}
                    InputProps={{
                      inputProps: {
                        type: "number",
                        max: getTotalPartCountForSelectedTrackerrowAndActivity(),
                        min: 0,
                      },
                    }}
                  />
                </Box>
                <Typography sx={{
                  fontSize: "55px",
                  fontWeight: 100,
                  marginX: "10px",
                }}>/</Typography>
                <Typography sx={{ fontSize: "18px" }}>{getTotalPartCountForSelectedTrackerrowAndActivity()}</Typography>
              </Box>
            </Box>
            <Box sx={{ position: "absolute", bottom: 10, right: 5 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                  margin: "15px",
                }}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ borderRadius: "6px", padding: "20px 56px" }}
                  onClick={() => {
                    handleClickNext();
                  }}
                  disabled={btnenable ? false : true}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal >

      <Modal
        open={openPierTorqueTubeModal}
        onClose={() => setOpenPierTorqueTubeModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "90vh",
            width: "60vw",
            backgroundColor: "#FFFFFF",
            color: "black",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "10px",
              margin: "5px"
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {blockDetails?.block}, Section {blockDetails?.section}, Row{" "}
                {blockDetails?.row}
              </Typography>
              <Typography sx={{ color: "#696F79", fontSize: "16px", paddingY: "3px" }}>
                {trackerTypeDetails?.tracker_type}
              </Typography>
            </Box>
            {/* <Box sx={{ marginLeft: "25px" }}>
              <SegmentedControl
                name="group"
                callback={(val) => {
                  setSelectedType(val);
                }}
                controlRef={useRef()}
                segments={[
                  {
                    label: (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography>Piers</Typography>
                      </Box>
                    ),
                    value: 1,
                    ref: useRef(),
                  },
                  {
                    label: (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography>Torque Tubes</Typography>
                      </Box>
                    ),
                    value: 0,
                    ref: useRef(),
                  },
                ]}
              /> */}

            {/* </Box> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <CloseIcon
                sx={{ padding: "10px 0  10px 15px" }}
                onClick={handleClosePierTorqueTubeModal}
              />
            </Box>
          </Box>
          <Divider />
          <Box sx={{ padding: "10px 15px" }}>
            <Typography>Choose piers and bays that are completed</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {isZimmerman === true ?
              <ConstructionProgressPierTTSelection toggleValue={selectedType} pierTorqueTubeData={zimmermanPierData} selectedTrackerrow={selectedRows} activityId={activityId} constructionProgressTrackerrowWise={constructionProgressTrackerrowWise} clearState={openPierTorqueTubeModal} />
              :
              <ConstructionProgressPierTTSelection toggleValue={selectedType} pierTorqueTubeData={piersTorqueTubesDetails} selectedTrackerrow={selectedRows} activityId={activityId} constructionProgressTrackerrowWise={constructionProgressTrackerrowWise} clearState={openPierTorqueTubeModal} />
            }
            <Box sx={{
              position: "absolute",
              bottom: 10,
              left: 5,
              margin: "10px",
            }}>
              <Button
                variant="contained"
                size="medium"
                sx={{ borderRadius: "6px", padding: "10px 40px" }}
                onClick={() => {
                  setOpenModal(true)
                  setOpenPierTorqueTubeModal(false)
                }}
              // disabled={btnenable ? false : true}
              >
                Back
              </Button>
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: 10,
                right: 5,
                margin: "10px",
              }}
            >
              <Button
                variant="contained"
                size="medium"
                sx={{ borderRadius: "6px", padding: "15px 50px" }}
                onClick={() => {
                  handleClick();
                }}
              // disabled={btnenable ? false : true}
              >
                Confirm
              </Button>
            </Box>
          </Box>

        </Box>

      </Modal >
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{ width: "440px" }}
      />
    </Box>
  );
};

export default ConstructionProgressChecksheet;
